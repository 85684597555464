/* eslint-disable radix */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable unicorn/prefer-add-event-listener */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Storage } from 'aws-amplify';
import {
  Container,
  ListGroupItem,
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Badge,
} from 'reactstrap';
import { Pagination, PaginationContent } from '@availity/pagination';
import { SearchControls } from '@/components';
import { Formik } from 'formik';
import { CsvUploadModal, ShareEmailModal, AgedLeadModal } from '@/modals';
import { FaEdit, FaFileCsv, FaPlus, FaShare, FaMailBulk } from 'react-icons/fa';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { vendorLayerPaginationQuery } from '@/graphql/queries';
import { useAdmin, useNetwork, usePageLoader } from '@/hooks';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import qs from 'querystring';
import omit from 'lodash.omit';
import { formatCurrencyForDisplay, Vars } from '@/utils';
import { SelectField } from '@availity/select';
import vendorLayerAggregation from '@/graphql/queries/vendorLayerAggregation';
import { AttributesProvider } from '@/context';

const getLeadSourceBadges = (vc: any) => {
  const getActiveDemandBadge = () => {
    if (vc?.filters?.some((f: any) => f.description === 'Active Demand'))
      return <Badge color="danger">Active Demand</Badge>;
    return null;
  };

  if (!vc?.pingPostEnabled && vc?.apiType === 'pingPost') {
    return (
      <div className="text-center">
        <Badge color="danger" className="d-flex align-items-center">
          <BsExclamationCircleFill className="mr-1" /> Ping Disabled
        </Badge>
      </div>
    );
  }

  if (vc.liveTransfersEnabled) {
    return (
      <div className="text-center">
        <Badge color="warning">Live Transfer</Badge>
        <div>{getActiveDemandBadge()}</div>
      </div>
    );
  }

  if (vc.apiType === 'pingPost') {
    return (
      <div className="text-center">
        <Badge color="success">Ping Post</Badge>
        <div>{getActiveDemandBadge()}</div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column">
      <Badge color="primary">Direct Post</Badge>
      <div>{getActiveDemandBadge()}</div>
    </div>
  );
};

const FilterBadge: React.FC<{ vc: any; color: string; filter: any }> = ({
  vc,
  color,
  filter,
}) => {
  return (
    <Badge
      key={`${filter.__typename}-${vc.layerID}`}
      className="ml-1 mt-1"
      color={color}
    >
      {filter.description}
    </Badge>
  );
};

const LeadSourcesRow: React.FunctionComponent<any> = ({
  setVendorInfo,
  setIsOpenUpload,
  setIsOpenShareEmail,
  history,
  ...vc
}) => {
  const { role } = useAdmin();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleEditClick = useCallback(() => {
    history.push(`/lead-sources/${vc.id}`);
  }, [history, vc]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen((b) => !b);

  return (
    <ListGroupItem tag={Row} noGutters className="d-flex mb-3" key={vc.id}>
      <Col
        xs={{
          size: 1,
        }}
        className="d-flex align-items-center justify-content-start"
      >
        <Button onClick={toggle} color="primary">
          <div className="d-flex align-items-center">
            <FaPlus />
          </div>
        </Button>
      </Col>

      <Col xs={2} className="d-flex flex-column text-center">
        <span className="h5">{vc.vendor?.vendorName}</span>
        <small>Vendor</small>
      </Col>
      <Col xs={3} className="d-flex flex-column text-center">
        <span className="h5">
          {vc.description ||
            vc.vendorSourceCampaign ||
            vc.vendorCampaigns?.[0]?.campaignName ||
            'default'}
        </span>
        <small>Name</small>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center">
        <span className="h5">{vc.vendorCampaigns?.length || 0}</span>
        <small>Categor{vc.vendorCampaigns?.length === 1 ? 'y' : 'ies'}</small>
      </Col>
      <Col xs={1} className="d-flex flex-column text-center pl-4">
        <span className="h5">
          {formatCurrencyForDisplay(
            vc.apiType !== 'pingPost' ? vc.vendorPrice * 100 : vc.fixedBidPrice
          )}
        </span>
        <small>{vc.apiType !== 'pingPost' ? '' : 'Bid'} Price</small>
      </Col>
      <Col
        xs={2}
        className="d-flex align-items-center justify-content-center pr-0 pl-5 h5 mb-0"
      >
        {getLeadSourceBadges(vc)}
      </Col>
      <Col
        xs={{
          size: 1,
          offset: 1,
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color="primary">
            Actions
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="d-flex align-items-center"
              onClick={handleEditClick}
            >
              <FaEdit /> &nbsp; Edit
            </DropdownItem>
            {['super_internal_admin', 'internal_admin'].includes(role) && (
              <DropdownItem
                className="d-flex align-items-center"
                onClick={() => {
                  setVendorInfo({
                    vendorID: vc.vendorID,
                    layerID: vc.layerID,
                    vendorSourceCampaign: vc.vendorSourceCampaign,
                    newAgedUpload: vc.newAgedUpload || false,
                  });
                  setIsOpenUpload(true);
                }}
              >
                <FaFileCsv /> &nbsp; Upload Leads
              </DropdownItem>
            )}
            <DropdownItem
              className="d-flex align-items-center"
              onClick={() => {
                setVendorInfo({
                  vendorID: vc.vendorID,
                  description: vc.description,
                  layerID: vc.layerID,
                });
                setIsOpenShareEmail(true);
              }}
            >
              <FaShare /> &nbsp; Share Intergration
            </DropdownItem>
            {['super_internal_admin', 'internal_admin'].includes(role) && (
              <DropdownItem
                className="d-flex align-items-center"
                disabled={vc.pendingLeads < 1}
                onClick={() => setIsModalOpen(true)}
              >
                <FaMailBulk /> &nbsp; Send leads
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Col>
      <Col xs={12}>
        <Collapse isOpen={isOpen} className="mx-3">
          <Row className="mt-3 py-1 bg-secondary rounded">
            <Col
              xs={6}
              className="d-flex align-items-center justify-content-start"
            >
              Pending Leads: {vc.pendingLeads}
            </Col>
            <Col
              xs={3}
              className="d-flex align-items-center justify-content-end"
            >
              Error Leads:{' '}
              {vc.errorLeads === 1000 ? '1000 >=' : vc.errorLeads || 0}
            </Col>
            <Col
              xs={3}
              className="d-flex pr-3 align-items-center justify-content-end"
            >
              Layer ID: {vc.layerID}
            </Col>
          </Row>
          <Row className="mt-3 py-1 bg-secondary rounded">
            <Col xs={12} className="d-flex align-items-center">
              <Col xs={2} className="px-0">
                Active Filters:
              </Col>
              <Col xs={10} className="px-0 ml-0">
                {vc.filters?.map((filter: any) => {
                  switch (filter.__typename) {
                    case 'VendorLayerSystemFilter':
                      return (
                        <FilterBadge filter={filter} vc={vc} color="danger" />
                      );
                    case 'VendorLayerRangeFilter':
                      return (
                        <FilterBadge filter={filter} vc={vc} color="warning" />
                      );
                    case 'VendorLayerVerifyFilter':
                      return (
                        <FilterBadge filter={filter} vc={vc} color="primary" />
                      );
                    case 'VendorLayerStaticFilter':
                      return (
                        <FilterBadge filter={filter} vc={vc} color="success" />
                      );
                    case 'VendorLayerMultiFilter':
                      return (
                        <FilterBadge filter={filter} vc={vc} color="info" />
                      );
                    case 'VendorLayerTimeFilter':
                      return (
                        <FilterBadge filter={filter} vc={vc} color="primary" />
                      );
                    case 'VendorLayerDynamicFilter':
                      return (
                        <FilterBadge filter={filter} vc={vc} color="light" />
                      );
                    default:
                      return null;
                  }
                })}
              </Col>
            </Col>
          </Row>
        </Collapse>
      </Col>
      <AgedLeadModal
        vendorID={vc.vendorID}
        layerID={vc.layerID}
        pendingLeads={vc.pendingLeads!}
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </ListGroupItem>
  );
};

const LeadSourcesPage: React.FunctionComponent<RouteComponentProps> = ({
  history,
}) => {
  const { network } = useNetwork();
  const client = useApolloClient();
  const location = useLocation();
  const initialSearch = useMemo(() => qs.parse(location.search.slice(1)), []);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const { start, complete } = usePageLoader();
  const [isOpenShareEmail, setIsOpenShareEmail] = useState(false);

  const [vendorInfo, setVendorInfo] = useState<any>({});

  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: Vars.bulkUploadBucket,
        region: 'us-east-1',
      },
    });
  }, []);

  // if we dont have a overflow price tier ID then this is not configured yet
  useEffect(() => {
    if (!network?.overflowPriceTierID) {
      history.push('/');
    }
  }, [network]);

  return (
    <Formik
      initialValues={{
        searchText: initialSearch?.q || '',
        vendorID: initialSearch?.vi || '',
        // Cannot use OR for JSON.parse else it throws: Uncaught (in promise) Error: A cross-origin error was thrown. React doesn't have access to the actual error object in development.
        legacySync: initialSearch.ls
          ? JSON.parse(initialSearch?.ls)
          : undefined,
        sort: {
          mpVendorCampaignName: 'DESC',
        },
      }}
      onSubmit={() => {}}
    >
      {({ values }) => {
        useEffect(() => {
          history.push(
            `/lead-sources?${qs.stringify({
              q: values.searchText,
              vi: values.vendorID,
              ls: values.legacySync,
            })}`
          );
        }, [values.searchText, values.vendorID, values.legacySync]);
        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          start();
          const response = await client.query<any>({
            query: vendorLayerPaginationQuery,
            fetchPolicy: 'network-only',
            variables: {
              perPage: itemsPerPage,
              page: currentPage,
              filter: {
                q: values.searchText,
                networkID: network?.networkID,
                vendorID: values.vendorID,
                legacySync: values.legacySync,
              },
              orderBy: values.sort,
            },
          });

          const currentPageData = response.data?.vendorLayerPagination.items;

          complete();
          return {
            totalCount: response.data.vendorLayerPagination.count,
            items: currentPageData,
          };
          // }
        };

        const filter = {
          q: values.searchText,
          vendorID: values.vendorID || undefined,
          networkID: network?.networkID,
        };

        const { data: vendorAggData } = useQuery(vendorLayerAggregation, {
          variables: {
            fields: ['vendorID.keyword'],
            filter: omit(filter, ['q']),
          },
        });

        return (
          <Container className="my-3">
            <div className="mt-5 mb-3  d-flex align-items-center justify-content-between">
              <h3 className="my-0">Lead Sources</h3>
              <Button
                color="primary"
                size="lg"
                onClick={() => history.push('/lead-sources/new')}
              >
                Create Lead Source
              </Button>
            </div>
            <Pagination
              resetParams={[
                values.searchText,
                values.vendorID,
                values.legacySync,
                values.sort,
              ]}
              itemsPerPage={25}
              items={fetchItems}
            >
              <SearchControls
                inputPlaceholder="Search by source name or ID"
                advancedSearch={
                  <Row style={{ minWidth: 530 }}>
                    <Col xs={6}>
                      <SelectField
                        name="vendorID"
                        label="Vendor"
                        isClearable
                        labelKey="key"
                        valueKey="key"
                        options={
                          vendorAggData?.vendorLayerAggregation.find(
                            (f) => f.field === 'vendorID.keyword'
                          ).counts || []
                        }
                      />
                    </Col>
                    <Col xs={6}>
                      <SelectField
                        name="legacySync"
                        label="Legacy Sync"
                        isClearable
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]}
                      />
                    </Col>
                  </Row>
                }
              />
              <PaginationContent
                component={LeadSourcesRow}
                itemKey="layerID"
                history={history}
                setIsOpenUpload={setIsOpenUpload}
                setIsOpenShareEmail={setIsOpenShareEmail}
                setVendorInfo={setVendorInfo}
              />
            </Pagination>
            <CsvUploadModal
              isOpen={isOpenUpload}
              vendorInfo={vendorInfo}
              close={() => setIsOpenUpload(false)}
            />
            <ShareEmailModal
              isOpen={isOpenShareEmail}
              vendorInfo={vendorInfo}
              close={() => setIsOpenShareEmail(false)}
            />
          </Container>
        );
      }}
    </Formik>
  );
};

export default LeadSourcesPage;
