import moment from 'moment';

// TODO - PR to DateRange on Availity React
export default {
  Today: {
    startDate: moment({ hour: 0 }), // today, 0:00:00.000
    endDate: moment({ hour: 0 }),
  },
  'Last 7 Days': {
    startDate: moment({ hour: 0 }).subtract(7, 'days'),
    endDate: moment({ hour: 0 }),
  },
  'Last 30 Days': {
    startDate: moment({ hour: 0 }).subtract(30, 'days'),
    endDate: moment({ hour: 0 }),
  },
  'Last 120 Days': {
    startDate: moment({ hour: 0 }).subtract(120, 'days'),
    endDate: moment({ hour: 0 }),
  },
  'Last 6 Months': {
    startDate: moment({ hour: 0 }).subtract(6, 'months'),
    endDate: moment({ hour: 0 }),
  },
  'Last 12 Months': {
    startDate: moment({ hour: 0 }).subtract(12, 'months'),
    endDate: moment({ hour: 0 }),
  },
};
