import gql from 'graphql-tag';
import { PriceTierBase } from './PriceTierFragment';

export const NetworkBase = `
    id
    __typename
    networkName
    networkID
    defaultManagerIDs
    defaultManagers {
      agentID
      firstName
      lastName
    }
    flags {
      allowMarketplaceBrokering
      allowMyAgents
      allowBeta
      allowVendors
      allowAttributes
    }
    allowedStates
    allowedVendors
    tags
    refundPolicy
    autoApproveAgents
    corpID
    brokerID
    broker {
      agentID
      firstName
      lastName
    }
    brokers {
      brokerName
      brokerID
    }
    duplicateLevels
    overflowPriceTierID
    marketplace
    companyID
    agentMinimumRefresh
    companyMinimumRefresh
    corpMinimumRefresh
    leadAgeDelay
    allowedLeadTiers {
      ${PriceTierBase}
    }
    logoURL
    refundsVisible
    allowDynamicPrice
    allowDynamicBidding
    allowCategories
    maxAgeAllowed
    networkCreated
    personal
    verticalType
    verticalTypes
    leadTypes
    buyerID
    povertyLevelIDs
    povertyLevels {
      id
      __typename
      name
      levels
      fieldNames
      key
      value
    }
    fees {
      id
      __typename
      type
      amount
      agentFundedPercent
    }
    reportOptions {
      label
      reportType
    }
    vendorLayerPrices {
      rejectDuplicates
      rejectDisconnected
      enhanceProfile
    }
    allowedStates
    availibleCRMs {
      iSalesCRM {
        crmUrl
        attributesID
        teamRoutingAttribute
        label
        crmUrlIDs {
          crmUrlID
          description
        }
        fields {
          required
          placeholder
          name
          type
          label
        }
      }
      vanillaSoftDirect {
        crmUrl
        attributesID
        teamRoutingAttribute
        label
        crmUrlIDs {
          crmUrlID
          description
        }
        fields {
          required
          placeholder
          name
          type
          label
        }
      }
      convoso {
        crmUrl
        attributesID
        label
        authToken
        fields {
          required
          placeholder
          name
          type
          label
        }
      }
    }
    adminDashboards {
      code
      name
      filters
      managers {
        name
        agentID
      }
    }
`;

const NetworkFragment = gql`
  fragment NetworkFragment on Network {
    ${NetworkBase}
  }`;

export default NetworkFragment;
