/* eslint-disable react/jsx-fragments */
import React, { useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  ModalProps,
  ModalHeader,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import Switch from 'react-switch';
import * as yup from 'yup';
import { Formik, FieldArray } from 'formik';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { SelectField } from '@availity/select';
import { useMutation, useApolloClient, useQuery } from '@apollo/react-hooks';
import { createQueueMutation } from '@/graphql/mutations';
import { queueManyQuery, priceTierManyQuery } from '@/graphql/queries';
import { NetworkContext, NotificationContext } from '@/context';
import { uuid } from 'uuidv4';
import { LoadingButton, SwitchButton } from '@/components';

interface QueueCreationModalProps {
  /**
   * Open Modal
   */
  isOpen: boolean;
  close: () => any;
  /**
   * The Versions to deploy
   */
  versions: string[];
}

const QueueCreationModal: React.FunctionComponent<QueueCreationModalProps> = ({
  isOpen,
  close,
  versions = [],
}) => {
  const client = useApolloClient();
  const {
    network: { marketplace, networkID },
  } = useContext(NetworkContext) as any;

  const { create } = useContext(NotificationContext);

  const { data: priceTierData, loading: priceTierLoading } = useQuery(
    priceTierManyQuery,
    {
      variables: {
        networkID,
      },
    }
  );

  const [createQueues, { loading: updating }] = useMutation(
    createQueueMutation,
    {
      update: (cache, { data }) => {
        const {
          createQueue: { success, queues },
        } = data as any;

        if (success) {
          const { queueMany = [] } = cache.readQuery({
            query: queueManyQuery,
            variables: {
              marketplace,
            },
          }) as any;
          cache.writeQuery({
            query: queueManyQuery,
            variables: {
              marketplace,
            },
            data: {
              queueMany: [...queueMany, ...queues],
            },
          });

          client.queryManager.broadcastQueries();
        } else {
          create('danger', 'Failed to deploy queues', 3000);
        }
      },
      onCompleted: () => {
        create('success', 'Successfully deployed queues', 3000);
        close();
      },
      onError: () => {
        create('danger', 'Failed to deploy queues', 3000);
      },
    }
  );

  if (priceTierLoading) return null;

  const { priceTierMany = [] } = priceTierData;
  return (
    <Modal isOpen={isOpen} toggle={close}>
      <Formik
        onSubmit={(values) => {
          createQueues({
            variables: {
              input: {
                networkID,
                loopDelay: values.loopDelay * 1000 || 0,
                status: 'ON',
                logs: values.logs ? 'ON' : 'OFF',
                version: values.version,
                tierIDs: values.tierIDs,
              },
            },
          });
        }}
        validationSchema={yup.object().shape({
          queues: yup.array().of(
            yup
              .object()
              .shape({
                id: yup.string(),
                tierIDs: yup.array().of(yup.string()),
                logs: yup.bool(),
                loopDelay: yup.number().default(0),
                version: yup.string().isRequired(),
              })
              .isRequired()
          ),
        })}
        initialValues={{
          id: uuid(),
          tierIDs: [],
          version: '',
          logs: false,
          loopDelay: 0,
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <React.Fragment>
            <ModalHeader toggle={close}>Create Queue</ModalHeader>
            <ModalBody tag={Row} noGutters>
              <Col xs={12}>
                <SelectField
                  name="tierIDs"
                  label="Price Tiers"
                  placeholder="Select a tier..."
                  styles={{
                    menuPortal: (base: any) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  labelKey="name"
                  isMulti
                  valueKey="priceTierID"
                  menuPortalTarget={document.body} // important
                  options={priceTierMany}
                />
              </Col>
              <Col xs={12}>
                <SelectField
                  name="version"
                  label="Version"
                  // raw
                  placeholder="Select a Version..."
                  styles={{
                    menuPortal: (base: any) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  menuPortalTarget={document.body} // important
                  options={JSON.parse(JSON.stringify(versions))
                    .reverse()
                    .map((key) => ({
                      label: key,
                      value: key,
                    }))}
                />
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label>Logs</Label>
                  <div className="mt-1">
                    <Switch
                      onChange={() => setFieldValue(`logs`, !values.logs)}
                      checked={values.logs}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs={8}>
                <FormGroup>
                  <Label>Loop Delay</Label>
                  <InputGroup>
                    <Input
                      name="loopDelay"
                      type="number"
                      step="1"
                      min={0}
                      max={100}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>seconds</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
              <Button color="secondary" onClick={close}>
                Cancel
              </Button>
              <LoadingButton
                color="success"
                onClick={handleSubmit}
                type="submit"
                loading={updating}
              >
                Save
              </LoadingButton>
            </ModalFooter>
          </React.Fragment>
        )}
      </Formik>
    </Modal>
  );
};

export default QueueCreationModal;
