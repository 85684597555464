import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import { Form, Field } from '@availity/form';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Alert,
} from 'reactstrap';
import * as yup from 'yup';
import { ServiceLoginModal } from '@/modals';
import useAgent from './useAgent';

const roleLabels: {
  active: string;
  paused: string;
} = {
  active: 'Active',
  paused: 'Paused',
};

const General: React.FC<RouteComponentProps<{
  id: string;
}>> = () => {
  const { user, loading, update } = useAgent();
  const [serviceActivationModalOpen, setServiceActivationModalOpen] = useState(
    false
  );

  if (loading || !user) {
    return null;
  }

  const actionButtons = ({
    setFieldValue,
    callerReadyAccountStatus,
    agentCallerReadyID,
    agentCallerReadyGRID,
    lastCallerReadyChargeResult,
    callerReadyStatus,
  }: {
    setFieldValue: any;
    callerReadyAccountStatus: string;
    agentCallerReadyID: string;
    agentCallerReadyGRID: string;
    lastCallerReadyChargeResult: string;
    callerReadyStatus: string;
  }): any => {
    if (['active', 'paused'].includes(user.callerReadyAccountStatus)) {
      return (
        <>
          <Col xs={2}>
            <FormGroup>
              <Label>Account Status</Label>
              <UncontrolledDropdown>
                <DropdownToggle caret>
                  {roleLabels[callerReadyAccountStatus]}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      setFieldValue('callerReadyAccountStatus', 'active')
                    }
                    active={callerReadyAccountStatus === 'active'}
                  >
                    Active
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      setFieldValue('callerReadyAccountStatus', 'pending')
                    }
                    active={callerReadyAccountStatus === 'pending'}
                  >
                    Paused
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup>
              <Label>Calling Status</Label>
              <UncontrolledDropdown>
                <DropdownToggle caret>
                  {roleLabels[callerReadyStatus]}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => setFieldValue('callerReadyStatus', 'active')}
                    active={callerReadyStatus === 'active'}
                  >
                    Active
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setFieldValue('callerReadyStatus', 'paused')}
                    active={callerReadyStatus === 'paused'}
                  >
                    Paused
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </FormGroup>
          </Col>
        </>
      );
    }
    if (
      callerReadyAccountStatus === 'pending' &&
      agentCallerReadyID &&
      agentCallerReadyGRID &&
      lastCallerReadyChargeResult === 'Success'
    ) {
      return (
        <Col xs={2}>
          <FormGroup>
            <Label>Account Status</Label>
            <Button
              color="primary"
              size="lg"
              onClick={() => setServiceActivationModalOpen(true)}
            >
              Activate
            </Button>
          </FormGroup>
        </Col>
      );
    }

    return null;
  };

  return (
    <>
      <Form
        initialValues={{
          callerReadyStatus: user.callerReadyStatus,
          callerReadyAccountStatus: user.callerReadyAccountStatus,
          agentCallerReadyID: user.agentCallerReadyID,
          agentCallerReadyGRID: user.agentCallerReadyGRID,
        }}
        validationSchema={yup.object().shape({
          callerReadyStatus: yup
            .string()
            .required('callerReadyStatus required.'),
          callerReadyAccountStatus: yup
            .string()
            .required('callerReadyAccountStatus required.'),
          agentCallerReadyID: yup
            .string()
            .required('agentCallerReadyID required.'),
          agentCallerReadyGRID: yup
            .string()
            .required('agentCallerReadyGRID required.'),
        })}
        onSubmit={({ ...values }, { resetForm }) => {
          update({
            variables: {
              input: {
                agentID: user.agentID,
                agentCallerReadyID: values.agentCallerReadyID,
                agentCallerReadyGRID: values.agentCallerReadyGRID,
                callerReadyStatus: values.callerReadyStatus,
                callerReadyAccountStatus: values.callerReadyAccountStatus,
                id: user.id,
                networkID: user.networkID,
              },
            },
            onCompleted: () => resetForm(),
          });
        }}
      >
        {({ values, initialValues, setFieldValue, resetForm }) => {
          return (
            <>
              <Card className="mb-3">
                <CardHeader>
                  <CardTitle tag="h5" className="py-2 h4 mb-0 text-uppercase">
                    Caller Ready
                  </CardTitle>
                </CardHeader>
                <CardBody tag={Row} noGutters>
                  {actionButtons({
                    setFieldValue,
                    callerReadyStatus: values.callerReadyStatus,
                    callerReadyAccountStatus: values.callerReadyAccountStatus,
                    agentCallerReadyID: user.agentCallerReadyID,
                    agentCallerReadyGRID: user.agentCallerReadyGRID,
                    lastCallerReadyChargeResult:
                      user.lastCallerReadyChargeResult,
                  })}

                  <Col xs={3}>
                    <Field
                      disabled={!user.callerReadyAccountStatus}
                      name="agentCallerReadyID"
                      label="Caller Ready ID"
                      placeholder="Caller Ready ID..."
                    />
                  </Col>
                  <Col xs={1} />
                  <Col xs={3}>
                    <Field
                      disabled={!user.callerReadyAccountStatus}
                      name="agentCallerReadyGRID"
                      label="Caller Ready Group ID"
                      placeholder="Caller Ready ID..."
                    />
                  </Col>
                  {['active', 'paused'].includes(
                    user.callerReadyAccountStatus
                  ) ? (
                    <Col xs={3} style={{ marginLeft: 10 }}>
                      <p>
                        {' '}
                        {`Last login: ${moment(
                          user.lastCallerReadyLogin
                        ).format('MM/DD/YYYY')}`}
                      </p>
                      <p>
                        {' '}
                        {`Last Succesful Charge Date: ${moment(
                          user.lastCallerReadyCharge
                        ).format('MM/DD/YYYY')}`}
                      </p>
                      <p>
                        {' '}
                        {`Last Charge Result: ${
                          user.lastCallerReadyChargeResult || 'No Charge Yet'
                        }`}{' '}
                      </p>
                    </Col>
                  ) : null}
                </CardBody>
              </Card>
              <div className="float-right">
                <Button
                  color="secondary"
                  type="reset"
                  className="mr-2"
                  size="lg"
                >
                  Reset
                </Button>
                <Button color="primary" size="lg" type="submit">
                  Save
                </Button>
              </div>
            </>
          );
        }}
      </Form>
      <ServiceLoginModal
        toggle={() => setServiceActivationModalOpen((o) => !o)}
        isOpen={serviceActivationModalOpen}
        user={user}
        serviceID="callerready"
      />
    </>
  );
};

export default General;
