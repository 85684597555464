import React from 'react';
import { Badge } from 'reactstrap';
import { useFormikContext } from 'formik';
import { FaTimes } from 'react-icons/fa';
import relativeRanges from '@/utils/relativeRanges';

interface SearchPageProps {
  name: string;
  value: string;
  disabled?: boolean;
  remove: () => void;
}

// TODO - abstract
const leadTypeOptions = [
  {
    label: 'Marketplace',
    value: 'vendor',
  },
  {
    label: 'Agent Direct',
    value: 'agentDirect',
  },
];

const getValue = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'true' : undefined;
  }

  if (typeof value === 'object') {
    if (value?.startDate === undefined || value?.endDate === 'undefined') {
      return null;
    }

    const startDate =
      typeof value.startDate === 'string'
        ? value.startDate
        : value.startDate?.format('YYYY-MM-DD');
    const endDate =
      typeof value.endDate === 'string'
        ? value.endDate
        : value.endDate?.format('YYYY-MM-DD');
    // date range
    const found = Object.entries(relativeRanges).find(([_, range]) => {
      if (
        range?.startDate.isSame(startDate) &&
        range?.endDate.isSame(endDate)
      ) {
        return true;
      }
    });

    return found ? found[0] : `${startDate} - ${endDate}`;
  }
  return value;
};

const SearchBadge: React.SFC<SearchPageProps> = ({
  name,
  value,
  remove,
  disabled,
}) => {
  const displayValue = getValue(value);
  return displayValue ? (
    <div className="h5 mr-2 mb-0">
      <Badge pill color="primary" className="d-flex">
        {name} : {displayValue}{' '}
        {!disabled && (
          <FaTimes className="ml-1 cursor-pointer" onClick={remove} />
        )}
      </Badge>
    </div>
  ) : null;
};
function toTitleCase(str: string) {
  return str?.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const SearchBadges: React.SFC = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div className="d-flex flex-row flex-wrap align-items-center">
      <span className="mr-3 pr-3 border-right">Filtering Search on: </span>
      <SearchBadge
        name="Range"
        value={values.dateRange}
        disabled
        remove={() =>
          setFieldValue('dateRange', {
            startDate: undefined,
            endDate: undefined,
          })
        }
      />
      <SearchBadge
        name="Date Type"
        value={values.dateType}
        remove={() => setFieldValue('dateType', 'soldDate')}
        disabled
      />
      <SearchBadge
        name="Lead Type"
        value={values.leadType}
        remove={() => setFieldValue('leadType', undefined)}
      />
      <SearchBadge
        name="Status"
        value={values.status}
        remove={() => setFieldValue('status', undefined)}
      />
      <SearchBadge
        name="Refund Type"
        value={values.refundType?.toLowerCase()}
        remove={() => setFieldValue('refundType', undefined)}
      />
      <SearchBadge
        name="search"
        value={values.searchText}
        remove={() => setFieldValue('searchText', '')}
      />
      <SearchBadge
        name="Duplicate"
        value={values.isDuplicate}
        remove={() => setFieldValue('isDuplicate', false)}
      />
      <SearchBadge
        name="Email Only"
        value={values.emailOnly}
        remove={() => setFieldValue('emailOnly', false)}
      />
      <SearchBadge
        name="Type"
        value={leadTypeOptions.find((o) => o.value === values.leadType)?.label}
        remove={() => setFieldValue('leadType', null)}
      />
      <SearchBadge
        name="Vendor Name"
        value={values.vendorName}
        remove={() => setFieldValue('vendorName', null)}
      />
      <SearchBadge
        name="Region"
        value={values.region}
        remove={() => setFieldValue('region', null)}
      />
      <SearchBadge
        name="Lead Source"
        value={values.mpVendorCampaignName}
        remove={() => setFieldValue('mpVendorCampaignName', null)}
      />
      <SearchBadge
        name="Type"
        value={toTitleCase(values.transferType)}
        remove={() => setFieldValue('leadType', null)}
      />
      <SearchBadge
        name="Vendor"
        value={values.rightFilter?.key}
        remove={() => setFieldValue('rightFilter', null)}
      />
      <SearchBadge
        name="Campaign"
        value={values.leftFilter?.name}
        remove={() => setFieldValue('leftFilter', null)}
      />
      <SearchBadge
        name="Agent"
        value={
          values.leftFilter
            ? `${values.leftFilter.firstName} ${values.leftFilter.lastName}`
            : null
        }
        remove={() => setFieldValue('leftFilter', null)}
      />
    </div>
  );
};

export default SearchBadges;
