import { useQuery, useMutation } from '@apollo/react-hooks';
import { PriceTier } from '@sonic-web-dev/types';
import {
  priceTierManyQuery,
  vendorCampaignManyTierQuery,
} from '@/graphql/queries';
import { ApolloQueryResult } from 'apollo-client';
import { upsertPriceTierMutation } from '@/graphql/mutations';
import uniq from 'lodash.uniq';
import { MutationFunctionOptions, ExecutionResult } from '@apollo/react-common';
import { useNetwork, useNotifications } from '@/hooks';
import { useHistory } from 'react-router-dom';

export interface CustomPriceTier
  extends Omit<Omit<PriceTier, 'fallbackMinutes'>, 'fallbackTierName'> {
  id?: string;
  mpVendorCampaignIDs: string[];
  fallbackPriceTierID: string;
  dynamicPrice?: boolean;
  minPrice?: number;
  maxPrice?: number;
  agentResponsiblePercentage?: number;
  overflowMpVendorCampaignIDs: string[];
  fallbackAmount: number;
  allowedFilters: string[];
  activeSpendingPeriods: number;
  redisPriceTier: {
    status: 'ON' | 'OFF' | null;
    serverStatus: 'ON' | 'OFF' | null;
    cycleDuration: number;
    lastCompletionEpoch: number;
  };
  liveTransfers: boolean;
  chargeDelayThreshold: number;
  liveTransferPrice: number;
  agentCreditOnly: boolean;
  addDupBackToQueue: boolean;
  skipDupCheck: boolean;
  bulkPurchase: boolean;
  bulkPurchaseMax: number;
  bulkPurchaseTimeout: number;
  bulkPurchaseCustom: boolean;
  isAged: boolean;
  isOverflow: boolean;
  tierType: string;
  allowedDestinationTypes: string[];
  analyticStreamOn: boolean;
  maxCampaignCount: number;
  maxDuplicates: number;
  maxLeadCountPerDay: number;
  priceBreakPoints: { breakPoint: number; breakPointPrice: number }[];
  bulkPurchaseMin: number;
  requiredDestination: string;
  bulkPurchaseOnly: boolean;
}

export interface UsePriceTiersResult {
  loading: boolean;
  updating: boolean;
  priceTiers?: CustomPriceTier[];
  vendors: any[];
  vendorCampaigns: any[];
  refetch: (
    variables?: Record<string, any> | undefined
  ) => Promise<
    ApolloQueryResult<{
      priceTierMany: any[];
    }>
  >;
  refetchVendorCampaigns: () => Promise<
    ApolloQueryResult<{ priceTierMany: any[] }>
  >;
  upsertPriceTier: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined
  ) => Promise<ExecutionResult<any>>;
}

const usePriceTiers = (): UsePriceTiersResult => {
  const history = useHistory();
  const { create: createNotification } = useNotifications();
  const { network } = useNetwork();
  const {
    data: priceTierData,
    loading: loadingPriceTiers,
    refetch,
  } = useQuery<{
    priceTierMany: any[];
  }>(priceTierManyQuery, {
    variables: {
      networkID: network?.networkID,
    },
  });
  const [upsertPriceTier, { loading: updating }] = useMutation(
    upsertPriceTierMutation,
    {
      onCompleted: () => {
        createNotification('success', `Successfully updated Price Tier`);
        history.push('/price-tiers');
      },
      onError: (error) =>
        createNotification(
          'danger',
          error.message.replace('GraphQL error:', '')
        ),
    }
  );

  const {
    data: vendorCampaignData,
    loading: loadingVendorCampaigns,
    refetch: refetchVendorCampaigns,
  } = useQuery(vendorCampaignManyTierQuery, {
    variables: {
      networkID: network?.networkID,
    },
  });

  const vendorCampaigns = vendorCampaignData?.vendorCampaignManyTierQuery || [];

  const vendors =
    uniq(vendorCampaigns.map((vc: any) => vc.vendorName)).map((v: any) => ({
      label: v,
      value: v,
    })) || [];

  return {
    loading: loadingVendorCampaigns || loadingPriceTiers,
    priceTiers: priceTierData?.priceTierMany.map((priceTier) => ({
      ...priceTier,
      assignedVendorCampaigns: priceTier.mpVendorCampaignIDs?.map(
        (vcID: string) => ({
          ...vendorCampaigns.find((vc: any) => vc.mpVendorCampaignID === vcID),
          mpVendorCampaignID: vcID,
        })
      ),
    })),
    vendorCampaigns,
    vendors,
    upsertPriceTier,
    updating,
    refetch,
    refetchVendorCampaigns: () =>
      refetchVendorCampaigns({
        networkID: network?.networkID,
      }),
  };
};

export default usePriceTiers;
