import React, { useEffect } from 'react';
import { Container, Button, Alert } from 'reactstrap';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { BackButton, LoadingButton } from '@/components';
import { Form } from '@availity/form';
import { useNetwork, useAdmin } from '@/hooks';
import { indexOf } from 'lodash';
import usePriceTier from './usePriceTier';
import General from './components/General';
import LeadSources from './components/LeadSources';
import BulkPriceBreakpoints from './components/BulkPriceBreakpoints';
import AdvancedSettings from './components/AdvancedSettings';
import AgedSettings from './components/AgedSettings';

// If new lead breakpoint quantity < previous breakpoint, then validation error.
// If new price breakpoint > previous breakpoint, then validation error.
const testBreakPoints = (val: number, schema: any, type: string): boolean => {
  const breakPointsArray = schema.from?.[1].value.priceBreakPoints;
  const index = indexOf(
    breakPointsArray,
    breakPointsArray.find((bp: any) => bp?.[type] === val)
  );
  const previousBreakPoint = breakPointsArray?.[index - 1]?.[type];

  if (breakPointsArray.filter((bp: any) => bp?.[type] === val).length > 1) {
    return false;
  }

  if (index === 0 && type === 'breakPoint') {
    return true;
  }

  if (
    (index === 0 &&
      val < schema.from?.[1].value.price &&
      type === 'breakPointPrice') ||
    !val
  ) {
    return true;
  }

  return type === 'breakPoint'
    ? val > previousBreakPoint
    : val < previousBreakPoint;
};

const DetailView: React.FunctionComponent<RouteComponentProps<{
  id: string;
}>> = ({ history }) => {
  const { network } = useNetwork();
  const { role } = useAdmin();

  const {
    loading,
    vendors,
    tier,
    upsertPriceTier,
    updating,
    vendorCampaigns,
  } = usePriceTier();

  // if we dont have a overflow price tier ID then this is not configured yet
  useEffect(() => {
    if (!network?.overflowPriceTierID) {
      history.push('/');
    }
  }, [network]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Form
      initialValues={{
        id: tier?.id,
        name: tier?.name || '',
        description: tier?.description || '',
        dynamicPrice: tier?.dynamicPrice || false,
        agentResponsiblePercentage: tier?.agentResponsiblePercentage || 100,
        price: tier?.price || 0,
        minPrice: tier?.dynamicPrice ? tier?.minPrice || 0 : 0,
        maxPrice: tier?.dynamicPrice ? tier?.maxPrice || 99999 : 999999,
        initialPrice: 100,
        allowedFilters: tier?.allowedFilters || ['region'],
        fallbackAmount: tier?.fallbackAmount || 2,
        fallbackIncrement: tier?.fallbackIncrement || 'minutes',
        fallbackPriceTierID:
          tier?.fallbackPriceTierID || network!.overflowPriceTierID,
        mpVendorCampaignIDs: tier?.mpVendorCampaignIDs || [],
        liveTransfers: tier?.liveTransfers || false,
        chargeDelayThreshold: tier?.chargeDelayThreshold || 0,
        liveTransferPrice: tier?.liveTransferPrice || 0,
        overflowMpVendorCampaignIDs: tier?.overflowMpVendorCampaignIDs || [],
        vendorFilter: vendors[0].label,
        isAged: tier?.isAged || false,
        isOverflow: tier?.isOverflow || false,
        agentCreditOnly: tier?.agentCreditOnly || false,
        skipDupCheck:
          tier?.skipDupCheck === undefined || tier?.skipDupCheck === null
            ? true
            : tier?.skipDupCheck,
        addDupBackToQueue: tier?.addDupBackToQueue || false,
        allowedDestinationTypes: tier?.allowedDestinationTypes || [],
        bulkPurchase: tier?.bulkPurchase || false,
        bulkPurchaseMax: tier?.bulkPurchaseMax || 0,
        bulkPurchaseTimeout:
          tier?.bulkPurchaseTimeout === 0 ? 0 : tier?.bulkPurchaseTimeout || 24,
        bulkPurchaseCustom: tier?.bulkPurchaseCustom || false,
        analyticStreamOn: tier?.analyticStreamOn || false,
        maxDuplicates: tier?.maxDuplicates || 5,
        maxCampaignCount: tier?.maxCampaignCount || 99999999,
        maxLeadCountPerDay: tier?.maxLeadCountPerDay || 99999999,
        priceBreakPointsEnabled: !!tier?.priceBreakPoints,
        priceBreakPoints: tier?.priceBreakPoints || [],
        bulkPurchaseMin: tier?.bulkPurchaseMin || 0,
        requiredDestination: tier?.requiredDestination || 'none',
        bulkPurchaseOnly: tier?.bulkPurchaseOnly || false,
      }}
      onSubmit={(values) => {
        upsertPriceTier({
          variables: {
            input: {
              id: values.id,
              name: values.name.trim(),
              description: values.description.trim(),
              price: values.dynamicPrice
                ? (values.minPrice + values.maxPrice) / 2
                : values?.price,
              dynamicPrice: values.dynamicPrice,
              minPrice: values.minPrice,
              maxPrice: values.maxPrice,
              initialPrice: values.initialPrice,
              agentResponsiblePercentage: values.agentResponsiblePercentage,
              allowedFilters: values?.allowedFilters || [],
              fallbackAmount: values.fallbackAmount,
              fallbackIncrement: values.fallbackIncrement,
              fallbackPriceTierID: values.fallbackPriceTierID,
              mpVendorCampaignIDs: values.mpVendorCampaignIDs,
              networkID: network!.networkID,
              liveTransfers: values?.liveTransfers,
              liveTransferPrice: values.liveTransferPrice,
              chargeDelayThreshold: values.chargeDelayThreshold,
              isAged: values.isAged,
              isOverflow: values.isOverflow,
              agentCreditOnly: values?.agentCreditOnly || false,
              skipDupCheck:
                values?.skipDupCheck === undefined ||
                values?.skipDupCheck === null
                  ? true
                  : values?.skipDupCheck,
              addDupBackToQueue: values?.addDupBackToQueue || false,
              allowedDestinationTypes: values?.allowedDestinationTypes || [],
              bulkPurchase: values.bulkPurchase,
              analyticStreamOn: values.analyticStreamOn,
              maxDuplicates: values?.maxDuplicates || 5,
              maxCampaignCount: values?.maxCampaignCount || 99999999,
              maxLeadCountPerDay: values?.maxLeadCountPerDay || 99999999,
              ...(values.bulkPurchase && {
                priceBreakPoints: values?.priceBreakPointsEnabled
                  ? values.priceBreakPoints.filter(
                      (bp: any) => bp?.breakPoint && bp?.breakPointPrice
                    )
                  : [],
                bulkPurchaseMin: values.bulkPurchaseMin,
                bulkPurchaseMax: values.bulkPurchaseMax,
                bulkPurchaseTimeout: values.bulkPurchaseTimeout,
                bulkPurchaseCustom: values.bulkPurchaseCustom,
              }),
              requiredDestination: values.requiredDestination,
              bulkPurchaseOnly: values.bulkPurchaseOnly,
            },
          },
        });
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required('This field is required.'),
        price: yup.number().min(0, 'Must be a positive price.'),
        minPrice: yup.number().min(0, 'Must be a positive price.'),
        maxPrice: yup.number().min(0, 'Must be a positive price.'),
        liveTransferPrice: yup.number().when(['liveTransfers'], {
          is: true,
          then: (schema: yup.NumberSchema) =>
            schema
              .positive()
              .min(
                yup.ref('price'),
                'Must be greater than or equal to your Data Only Price.'
              ),
        }),
        chargeDelayThreshold: yup.number().when(['liveTransfers'], {
          is: true,
          then: (schema: yup.NumberSchema) =>
            schema.min(0, 'Time must be a positive number.').required(),
        }),
        agentResponsiblePercentage: yup
          .number()
          .min(0, 'Must be greater than or equal to 0.'),
        initialPrice: yup
          .number()
          .min(1, 'Default Price Must be greater than 0.'),
        fallbackAmount: yup
          .number()
          .min(1, 'Fallback tier timeout must be at least 1.'),
        fallbackIncrement: yup
          .string()
          .oneOf(['minutes', 'seconds', 'hours', 'days'])
          .required(),
        fallbackPriceTierID: yup.string(),
        description: yup.string().required('This field is required.'),
        mpVendorCampaignIDs: yup.array().when(['dynamicPrice'], {
          is: true,
          otherwise: (schema: yup.ArraySchema<string>) =>
            schema.of(yup.string()),
          then: (schema: yup.ArraySchema<string>) =>
            schema.of(
              yup
                .string()
                .test(
                  'vendor-campaign-required-price',
                  'Default Price is required for Dynamic Pricing.',
                  (val: any): boolean => {
                    const vendorCampaign = vendorCampaigns.find(
                      (vc) => val === vc.mpVendorCampaignID
                    );

                    return (
                      vendorCampaign.fixedBidPrice || vendorCampaign.vendorPrice
                    );
                  }
                )
            ),
        }),
        bulkPurchaseTimeout: yup
          .number()
          .min(0, 'Cannot have a max amount less than 1.')
          .test(
            'is-decimal',
            'Cannot Contain Decimals',
            (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
          )
          .test('min-timeout-non-admins', '1 Hour minimum.', (value) => {
            if (
              value === 0 &&
              network?.networkName !== 'Shield (QA)' &&
              network?.networkName !== 'Sword (DEV)'
            ) {
              return false;
            }
            return true;
          }),

        bulkPurchaseMax: yup
          .number()
          .test(
            'is-decimal',
            'Cannot Contain Decimals',
            (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
          )
          .when('bulkPurchase', {
            is: true,
            then: (schema: yup.NumberSchema) =>
              schema.min(1, 'Cannot have a max amount less than 1.'),
            otherwise: (schema: yup.NumberSchema) => schema,
          }),
        maxDuplicates: yup
          .number()
          .min(0, 'Cannot have a max amount less than 1.')
          .test(
            'is-decimal',
            'Cannot be decimal.',
            (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
          ),
        maxCampaignCount: yup
          .number()
          .min(1, 'Must be more than 1.')
          .test(
            'is-decimal',
            'Cannot be decimal.',
            (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
          ),
        maxLeadCountPerDay: yup
          .number()
          .min(1, 'Must be more than 1.')
          .test(
            'is-decimal',
            'Cannot be decimal.',
            (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
          ),
        priceBreakPoints: yup.array().of(
          yup.object().shape({
            breakPoint: yup
              .number()
              .min(0, 'Cannot be negative.')
              .test(
                'greater-than',
                'Must be greater than previous breakpoint.',
                (val, schema) => testBreakPoints(val, schema, 'breakPoint')
              ),
            breakPointPrice: yup
              .number()
              .min(1, 'Must be a positive price.')
              .test(
                'greater-than',
                'Price must be lower than previous breakpoint.',
                (val, schema) => testBreakPoints(val, schema, 'breakPointPrice')
              ),
          })
        ),
        bulkPurchaseMin: yup
          .number()
          .min(0, 'Cannot be negative.')
          .test(
            'is-decimal',
            'Cannot be decimal.',
            (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
          ),
      })}
    >
      {({ values, initialValues }) => (
        <Container className="mt-5">
          <div className="mb-4 d-flex align-items-center">
            <BackButton
              hideText
              color="primary"
              path="/price-tiers"
              className="mr-2"
            />
            <h3 className="font-weight-bold mb-0">
              {tier ? tier.name : 'Create Price Tier'}
            </h3>
          </div>
          {values.dynamicPrice && !initialValues.dynamicPrice && (
            <Alert color="warning">
              Note enabling dynamic tier pricing means you should verify price
              on all lead sources or be sure to include a default vendor price
              greater than 0 to prevent leads from selling for $0
            </Alert>
          )}
          <General />
          {values.isAged && <AgedSettings />}
          {values.bulkPurchase && <BulkPriceBreakpoints />}
          {role === 'super_internal_admin' && <AdvancedSettings />}
          {!values.liveTransfers && <LeadSources />}
          <div className="d-flex align-items-center justify-content-end">
            <Button
              onClick={() => history.push('/price-tiers')}
              className="mr-3"
              size="lg"
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              size="lg"
              loading={updating}
            >
              Save
            </LoadingButton>
          </div>
        </Container>
      )}
    </Form>
  );
};

export default DetailView;
