/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import moment from 'moment';
import { currencyFormater } from '@/utils';
import { Badge, Button } from 'reactstrap';
import { FaCheckCircle, FaDownload, FaTimesCircle } from 'react-icons/fa';
import { Storage } from 'aws-amplify';

export const sizeOptions = [
  {
    label: '2',
    value: 2,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const RecordingDownload: React.FC<{ url: string }> = ({ url }) => {
  const downloadTag = useRef<any>();
  return (
    <>
      <Button
        size="sm"
        title="Download Report"
        color="primary"
        className="ml-1"
        onClick={() => {
          downloadTag.current.click();
        }}
      >
        <FaDownload />
      </Button>
      <a ref={downloadTag} href={url} target="_blank" download />
    </>
  );
};
export const availableColumns = [
  {
    dataField: 'date',
    displayName: 'Date',
    formatter: (value: string): string =>
      moment(value).format('MM/DD/YY hh:mm A'),
  },
  {
    dataField: 'duplicateFromDate',
    displayName: 'Duplicate From',
    formatter: (value: number): string => {
      if (!value) {
        return 'N/A';
      }
      const date = new Date(Math.round(value / 1000));

      return `${date.getUTCMinutes()} m ${date.getUTCSeconds()} s`;
    },
  },
  {
    dataField: 'addedFromDate',
    displayName: 'Added From',
    formatter: (value: number): string => {
      if (!value) {
        return 'N/A';
      }
      const date = new Date(Math.round(value / 1000));

      return `${date.getUTCMinutes()} m ${date.getUTCSeconds()} s`;
    },
  },
  {
    dataField: 'createdEpoch',
    displayName: 'Created Epoch',
  },
  {
    dataField: 'leadID',
    displayName: 'ID',
  },
  {
    dataField: 'corpLeadID',
    displayName: 'Corp Lead ID',
  },
  {
    dataField: 'vendorLeadID',
    displayName: 'Vendor Lead ID',
  },
  {
    dataField: 'campaignName',
    displayName: 'Campaign',
  },
  {
    dataField: 'mpVendorCampaignName',
    displayName: 'Category',
  },
  {
    dataField: 'mpVendorCampaignID',
    displayName: 'Category ID',
  },
  {
    dataField: 'vendorName',
    displayName: 'Vendor',
  },
  {
    dataField: 'agentEmail',
    displayName: 'Agent Email',
  },
  {
    dataField: 'price',
    displayName: 'Price',
    formatter: (value: number): string => currencyFormater.format(value),
  },
  {
    dataField: 'vendorPrice',
    displayName: 'Vendor Price',
    formatter: (value: number): string => currencyFormater.format(value) || '',
  },
  {
    dataField: 'email',
    displayName: 'Email',
  },
  {
    dataField: 'firstName',
    displayName: 'First Name',
  },
  {
    dataField: 'lastName',
    displayName: 'Last Name',
  },
  {
    dataField: 'orgPhone',
    displayName: 'Phone',
  },
  {
    dataField: 'region',
    displayName: 'Region',
  },
  {
    dataField: 'crmContactID',
    displayName: 'CRM ID',
  },
  {
    dataField: 'leadType',
    displayName: 'Lead Type',
  },
  {
    dataField: 'isDuplicate',
    displayName: 'Is Duplicate',
    formatter: (value) => (
      <Badge color={!value ? 'success' : 'danger'}>
        {value ? <FaCheckCircle /> : <FaTimesCircle />}
      </Badge>
    ),
  },
  {
    dataField: 'isCorpDuplicate',
    displayName: 'Corporate Duplicate',
    formatter: (value) => (
      <Badge color={!value ? 'success' : 'danger'}>
        {value ? <FaCheckCircle /> : <FaTimesCircle />}
      </Badge>
    ),
  },
  {
    dataField: 'passDNC',
    displayName: 'Black List',
    formatter: (value) => (
      <Badge color={value ? 'success' : 'danger'}>
        {value ? <FaCheckCircle /> : <FaTimesCircle />}
      </Badge>
    ),
  },
  {
    dataField: 'result',
    displayName: 'Result',
  },
  {
    dataField: 'conferenceRecording',
    displayName: 'Conference Recording',
    formatter: (val: string) => {
      if (!val) return null;
      console.log(val);

      // Storage.get(val)
      //   .then((data: any) => console.log(data))
      //   .catch((error) => console.error(error));
      return <RecordingDownload url={val} />;
    },
  },
];
