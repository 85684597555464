/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Row,
  Col,
  InputGroup,
} from 'reactstrap';
import qs from 'querystring';
import { Storage } from 'aws-amplify';
import Pagination, {
  PaginationContent,
  usePagination,
} from '@availity/pagination';
import {
  FaSort,
  FaSortDown,
  FaSortUp,
  FaLock,
  FaPhone,
  FaCircle,
  FaUserFriends,
  FaCalendarMinus,
} from 'react-icons/fa';

import { Formik } from 'formik';
import { Input } from '@availity/form';
import { SelectField } from '@availity/select';
import { DateRangeField } from '@availity/date';
import moment from 'moment';
import * as yup from 'yup';
import { dateRange } from '@availity/yup';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';

import {
  leadPaginationQuery,
  leadAggregationQuery,
  reportManyQuery,
} from '@/graphql/queries';
import { LeadsShowExportModal, TableOptionsModal } from '@/modals';
import { availableColumns } from '@/utils/leadTableHelpers';
import { Vars } from '@/utils';
import access_obj from '@/utils/uiAccess';
import { SearchControls, SearchSummary } from '@/components';
import { useNetwork, useAdmin, useNotifications } from '@/hooks';
import omit from 'lodash.omit';

interface ColumnConfig {
  dataField: string;
  displayName: string;
  formatter?: (value: any) => any;
}

interface TableRowProps {
  [key: string]: any;
  columns: ColumnConfig[];
  sortKey: string;
  descending: boolean;
}

const leadTypeOptions = [
  {
    label: 'Marketplace',
    value: 'vendor',
  },
  {
    label: 'Agent Direct',
    value: 'agentDirect',
  },
];

const getIcon = (icon: string, color: string) => {
  switch (icon) {
    case 'phone':
      return <FaPhone color={color} />;
    case 'lock':
      return <FaLock color={color} />;
    case 'people':
      return <FaUserFriends color={color} />;
    default:
      return <FaCircle color={color} />;
  }
};
const getColor = (type: string): string => {
  switch (type) {
    case 'reject':
      return 'red';
    case 'block':
      return 'yellow';
    case 'pass':
      return 'green';
    default:
      return 'green';
  }
};

const initialColumns = localStorage.getItem('leads-table-columns')?.split(',');

const TableRow: React.SFC<TableRowProps> = ({
  columns,
  filters,
  sortKey,
  descending,
  ...rest
}) => (
  <tr>
    {columns.map((column, key) => {
      const { dataField, formatter } = column;
      if (dataField === 'result') {
        const { icon = '', result = '' } = rest[dataField] || {};
        return (
          <td key={`${key}-${dataField}`}>
            {getIcon(icon, getColor(rest.status))}
          </td>
        );
      }

      if (dataField === 'date') {
        const value = rest[filters?.dateType];
        return (
          <td key={`${key}-date`}>
            {formatter !== undefined ? formatter(value) : value}
          </td>
        );
      }

      return (
        <td key={`${key}-${dataField}`}>
          {formatter !== undefined
            ? formatter(rest[dataField])
            : rest[dataField]}
        </td>
      );
    })}
  </tr>
);

const NoItemsMessage: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...rest
}) => {
  const { page, loading } = usePagination();

  if (loading && (!page || page.length === 0)) {
    return <span {...rest}>Loading Items...</span>;
  }

  return <span {...rest}>{!page || (!page.length && children)}</span>;
};

const Leads: React.SFC = () => {
  const history = useHistory();
  const location = useLocation();
  const initialSearch = useMemo(() => qs.parse(location.search.slice(1)), []);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showTableOptionsModal, setTableOptionsModal] = useState(false);
  const [elasticQuery, setElasticQuery] = useState<any>({});

  const { create } = useNotifications();

  const { role } = useAdmin();
  const { network } = useNetwork();
  const { data = {}, loading } = useQuery(reportManyQuery, {
    skip: !network?.networkID,
    variables: {
      folderID: network.networkID,
      reportFolder: 'network',
    },
  });
  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: `reports.leadarena-${Vars.env}`,
        region: 'us-east-1',
      },
    });
  }, []);
  const exportItems = async ({ columns, ...values }: any): Promise<void> => {
    setShowExportModal(true);

    const filePath = `leads/exports/searchPayload-${moment().format()}.json`;

    const index = columns.findIndex(
      (s: string) => s === 'date' || s === 'created' || s === 'soldDate'
    );

    if (index !== -1)
      columns[index] = elasticQuery.filter.created ? 'created' : 'soldDate';

    Storage.vault.put(
      filePath,
      JSON.stringify({
        ...elasticQuery,
        fields: columns.filter((item: string) => item !== 'result'),
      }),
      {
        level: 'private',
        contentType: 'application/json',
        bucket: `report-filters-${Vars.env}`,
        region: 'us-east-1',
      }
    );
  };

  const { reportMany = [] } = data;

  const noGeneratingReports = reportMany.every(
    ({ status }: any) => status !== 'GENERATING'
  );

  if (loading) return null;

  return (
    <Formik<{
      size: number;
      searchText: string;
      sort: any;
      dateType: string;
      dateRange: any;
      vendorName?: any;
      mpVendorCampaignName?: any;
      columns: string[];
      leadType?: string;
      region?: string;
      isDuplicate: boolean;
      emailOnly: boolean;
      isSold?: boolean;
    }>
      initialValues={{
        size: localStorage.getItem('leads-table-page-size')
          ? // eslint-disable-next-line radix
            parseInt(localStorage.getItem('leads-table-page-size')!)
          : 25,
        isSold: undefined,
        searchText: initialSearch.q || '',
        sort: {},
        dateType: initialSearch.dt || 'soldDate',
        dateRange:
          initialSearch.fr && initialSearch.to
            ? {
                startDate: moment(initialSearch.fr, ['YYYY-MM-DD']),
                endDate: moment(initialSearch.to, ['YYYY-MM-DD']),
              }
            : {
                startDate: moment({ hour: 0 }),
                endDate: moment({ hour: 0 }),
              },
        columns: availableColumns
          .filter(({ dataField }) =>
            access_obj[role].lead.allowedColumns.includes(dataField)
          )
          .filter(
            (aC) =>
              !initialColumns || initialColumns.find((c) => c === aC.dataField)
          )
          .map((aC) => aC.dataField),
        vendorName: initialSearch.v,
        region: initialSearch.r,
        mpVendorCampaignName: initialSearch.vc,
        isDuplicate: initialSearch.d === 'true',
        emailOnly: initialSearch.e === 'true',
        leadType: initialSearch.lt || 'unknown',
      }}
      validationSchema={yup.object().shape({
        size: yup.number(),
        searchText: yup.string(),
        dateRange: dateRange({}),
        sort: yup.object(),
        dateType: yup.string(),
        vendorName: yup.string().nullable(), // campaignID
        mpVendorCampaignName: yup.string().nullable(), // vendorName || none for transfers
        isSold: yup.bool().nullable(),
        leadType: yup.string().nullable(),
      })}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue, errors }) => {
        const client = useApolloClient();

        const created = {
          gte: values.dateRange.startDate,
          lte: values.dateRange.endDate,
        };

        const filter = {
          q: values.searchText,
          vendorName: values.vendorName || undefined,
          mpVendorCampaignName: values.mpVendorCampaignName || undefined,
          leadType: values.leadType || undefined,
          emailOnly: values.emailOnly,
          isDuplicate: values.isDuplicate,
          networkID: network?.networkID,
          region: values.region || undefined,
          isSold: values.dateType === 'soldDate' ? true : values.isSold,
          [values.dateType]:
            created.gte !== undefined && created.lte !== undefined
              ? created
              : undefined,
        };

        const { data: leadAggData } = useQuery(leadAggregationQuery, {
          variables: {
            fields: [
              'mpVendorCampaignName.keyword',
              'vendorName.keyword',
              'region.keyword',
            ],
            filter: omit(filter, [
              'mpVendorCampaignName',
              'vendorName',
              'region',
            ]),
          },
        });

        useEffect(() => {
          localStorage.setItem(
            'leads-table-columns',
            values.columns.toString()
          );
        }, [values.columns]);

        useEffect(() => {
          history.push(
            `/leads?${qs.stringify({
              q: values.searchText,
              v: values.vendorName,
              vc: values.mpVendorCampaignName,
              t: values.leadType,
              e: values.emailOnly,
              d: values.isDuplicate,
              dt: values.dateType,
              lt: values.leadType,
              fr:
                typeof values.dateRange.startDate === 'string'
                  ? values.dateRange.startDate
                  : values.dateRange.startDate?.format('YYYY-MM-DD'),
              to:
                typeof values.dateRange.endDate === 'string'
                  ? values.dateRange.endDate
                  : values.dateRange.endDate?.format('YYYY-MM-DD'),
              r: values.region,
            })}`
          );
        }, [
          values.emailOnly,
          values.isDuplicate,
          values.leadType,
          values.searchText,
          values.vendorName,
          values.mpVendorCampaignName,
          values.dateRange,
          values.dateType,
          values.region,
        ]);

        useEffect(() => {
          localStorage.setItem('leads-table-page-size', values.size.toString());
        }, [values.size]);

        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          const response = await client.query<any>({
            query: leadPaginationQuery,
            variables: {
              perPage: itemsPerPage,
              page: currentPage,
              filter,
              orderBy: values.sort,
            },
          });

          const currentPageData = response.data?.leadPagination.items;

          setElasticQuery({
            type: 'admin-lead',
            folderID: network!.networkID,
            reportFolder: 'network',
            filter: {
              dateField: values.dateType,
              startDate: values.dateRange.startDate,
              endDate: values.dateRange.endDate,
              ...filter,
              brokerID: network?.brokerID,
            },
          });

          return {
            totalCount: response.data.leadPagination.count,
            items: currentPageData,
          };
          // }
        };

        const getSortIcon = (dataField: string): React.ReactNode => {
          if (dataField === 'date') {
            dataField = values.dateType;
          }

          const { [dataField]: sort } = values.sort;

          if (sort === 'DESC') {
            return <FaSortDown />;
          }

          if (sort === 'ASC') {
            return <FaSortUp />;
          }

          return <FaSort />;
        };

        const updateSort = (dataField: string): void => {
          if (dataField === 'date') {
            dataField = values.dateType;
          }

          const { [dataField]: sort, ...restSort } = values.sort;

          if (!sort) {
            setFieldValue('sort', {
              ...values.sort,
              [dataField]: 'DESC',
            });
          } else if (sort === 'DESC') {
            setFieldValue('sort', {
              ...values.sort,
              [dataField]: 'ASC',
            });
          } else {
            setFieldValue('sort', restSort);
          }
        };
        const route_map = {
          export: (
            <DropdownItem
              onClick={() => {
                if (errors && errors.dateRange) {
                  create('danger', 'Please fix search range before exporting');
                } else if (!noGeneratingReports) {
                  create(
                    'danger',
                    'You can only generate one report at a time.',
                    3000
                  );
                } else {
                  exportItems(values);
                }
              }}
              key="export-items"
            >
              Export
            </DropdownItem>
          ),
        } as any;

        const actions: any = useMemo(
          () =>
            access_obj[role].lead.actions.map(
              (action: any) => route_map[action]
            ),
          [role, elasticQuery, values]
        );

        const displayColumns = useMemo(
          () =>
            availableColumns.filter((aC) =>
              values.columns.find((c) => c === aC.dataField)
            ),
          [values.columns]
        );
        return (
          <>
            <Pagination
              items={fetchItems}
              itemsPerPage={values.size}
              watchList={[values.sort]}
              resetParams={[
                values.size,
                values.mpVendorCampaignName,
                values.vendorName,
                values.region,
                values.leadType,
                values.searchText,
                values.dateRange,
                values.isDuplicate,
                values.emailOnly,
                values.dateType,
                values.isSold,
              ]}
            >
              <Card className="my-5 mx-3">
                <CardHeader>
                  <CardTitle
                    tag="h3"
                    className="d-flex justify-content-between align-items-center mb-0"
                  >
                    Leads
                    <div>
                      {access_obj[role].lead.actions.length > 0 && (
                        <UncontrolledButtonDropdown>
                          <DropdownToggle
                            id="actions-dropdown"
                            color="dark"
                            caret
                          >
                            Actions
                          </DropdownToggle>
                          <DropdownMenu right>{actions}</DropdownMenu>
                        </UncontrolledButtonDropdown>
                      )}
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <SearchControls
                    openTableOptionsModal={() =>
                      setTableOptionsModal((o) => !o)
                    }
                    advancedSearch={
                      <Row style={{ maxWidth: 530 }}>
                        <Col xs={12}>
                          <DateRangeField
                            className="leads-date-range"
                            name="dateRange"
                            id="dateRange"
                            label="Date Range"
                            customArrowIcon="-"
                            calendarIcon={
                              <div>
                                <FaCalendarMinus />
                              </div>
                            }
                            datepickerProps={{
                              renderMonthElement: undefined,
                            }}
                            ranges
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="dateType"
                            label="Date Type"
                            options={[
                              { label: 'Created Date', value: 'created' },
                              { label: 'Sold Date', value: 'soldDate' },
                            ]}
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="region"
                            label="Region"
                            isClearable
                            labelKey="key"
                            valueKey="key"
                            options={
                              leadAggData?.leadAggregation.find(
                                (f) => f.field === 'region.keyword'
                              ).counts || []
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="vendorName"
                            label="Vendor"
                            isClearable
                            labelKey="key"
                            valueKey="key"
                            options={
                              leadAggData?.leadAggregation.find(
                                (f) => f.field === 'vendorName.keyword'
                              ).counts || []
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="mpVendorCampaignName"
                            label="Lead Source"
                            isClearable
                            labelKey="key"
                            valueKey="key"
                            getOptionLabel={({ key }: any) =>
                              key
                                .split('_')
                                .splice(
                                  key.startsWith('next') ||
                                    (key.startsWith('quote') &&
                                      !key.startsWith('quotewizard'))
                                    ? 2
                                    : 1
                                )
                                .join(' ')
                            }
                            options={
                              leadAggData?.leadAggregation.find(
                                (f) =>
                                  f.field === 'mpVendorCampaignName.keyword'
                              ).counts || []
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <div className="d-flex align-items-center">
                            <InputGroup className="d-flex justify-content-end w-auto">
                              <SelectField
                                label="Type"
                                className="left-filter-select"
                                placeholder="Lead Type..."
                                options={leadTypeOptions}
                                name="leadType"
                                isClearable
                              />
                            </InputGroup>
                          </div>
                        </Col>
                        {values.dateType === 'created' && (
                          <Col xs={6}>
                            <div className="d-flex align-items-center">
                              <InputGroup className="d-flex justify-content-end w-auto">
                                <SelectField
                                  label="Is Sold"
                                  className="left-filter-select"
                                  placeholder="is Sold..."
                                  options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                  ]}
                                  name="isSold"
                                  isClearable
                                />
                              </InputGroup>
                            </div>
                          </Col>
                        )}
                        <Col xs={12}>
                          <div style={{ display: 'inline-block' }}>
                            <label style={{ marginRight: 25 }}>
                              Is Duplicate:
                            </label>
                            <Input type="checkbox" name="isDuplicate" />
                          </div>
                          <div
                            style={{ display: 'inline-block', marginLeft: 30 }}
                          >
                            <label style={{ marginRight: 25 }}>
                              Email Only:
                            </label>
                            <Input type="checkbox" name="emailOnly" />
                          </div>
                        </Col>
                      </Row>
                    }
                  />
                  <SearchSummary className="d-flex justify-content-between py-3" />
                  {/* {displayList ? (
                    <PaginationContent
                      itemKey="leadID"
                      component={LeadListItem}
                      containerTag={ListGroup}
                    />
                  ) : ( */}
                  <Table striped size="sm" hover>
                    <thead>
                      <tr>
                        {/* <th aria-label="break-label" /> */}
                        {displayColumns.map((column: any) => (
                          <th key={column.displayName}>
                            <div
                              className="d-flex align-items-center cursor-pointer"
                              role="button"
                              tabIndex={0}
                              onClick={() => updateSort(column.dataField)}
                              onKeyDown={(e: React.KeyboardEvent) =>
                                e.charCode === 13 &&
                                updateSort(column.dataField)
                              }
                            >
                              {column.displayName}{' '}
                              {getSortIcon(column.dataField)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <PaginationContent
                      itemKey="leadID"
                      component={TableRow}
                      containerTag="tbody"
                      filters={values}
                      columns={displayColumns}
                    />
                  </Table>
                  {/* )} */}
                  <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
                    There are no items left to view
                  </NoItemsMessage>
                </CardBody>
              </Card>
            </Pagination>

            <LeadsShowExportModal
              isOpen={showExportModal}
              toggle={() => setShowExportModal((o) => !o)}
            />
            <TableOptionsModal
              availableColumns={availableColumns}
              isOpen={showTableOptionsModal}
              toggle={() => setTableOptionsModal((o) => !o)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Leads;
