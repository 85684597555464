import gql from 'graphql-tag';
import { QueueFragment } from '../fragments';

export default gql`
  query queueManyQuery($networkID: String!) {
    queueMany(networkID: $networkID) {
      versions
      queues {
        ...QueueFragment
      }
    }
  }
  ${QueueFragment}
`;
