import { useAdmin, useNetwork, useNotifications } from '@/hooks';
import React from 'react';
import * as yup from 'yup';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap';
import { SelectField } from '@availity/select';
import { useQuery } from 'react-query';
import {
  ButtonToggle,
  ManagerSelect,
  MoneyField,
  PriceTierSelect,
  SwitchButton,
} from '@/components';
import { Form, Field } from '@availity/form';
import { Vars } from '@/utils';
import classNames from 'classnames';

const SelectStates = () => {
  const {
    isLoading: isRegionsLoading,
    data: regionData,
  } = useQuery('regionData', () =>
    fetch(
      `https://lead-arena-config.s3.amazonaws.com/${Vars.env}/states.json`
    ).then((res) => res.json())
  );

  if (isRegionsLoading) return null;

  return (
    <SelectField
      name="allowedStates"
      label="Allowed States"
      placeholder="Allowed States"
      options={regionData}
      labelKey="label"
      valueKey="value"
      isMulti
      isClearable
    />
  );
};

const IncludePriceSwitch: React.FC<{
  name: string;
  value: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}> = ({ name, value, setFieldValue }) => {
  return (
    <Col xs={2}>
      <div className="text-center my-2">Include</div>
      <div
        id={`include-${name.toLowerCase()}-price`}
        className="text-center mb-2"
      >
        <SwitchButton
          width={90}
          checked={value}
          onlabel="True"
          offlabel="False"
          onstyle="success"
          offstyle="danger"
          onChange={() => setFieldValue(`include${name}Price`, !value)}
        />
      </div>
    </Col>
  );
};

const NetworksGeneralTab: React.FC = () => {
  const { create: createNotification } = useNotifications();
  const { network, update, updating } = useNetwork({
    createNotification,
  });
  const { role } = useAdmin();

  const processingFees = (field: string): object | undefined => {
    return network?.fees?.filter((fee) => fee.type === field)?.[0];
  };

  return (
    <div>
      <Form
        initialValues={{
          brokerName: network?.broker
            ? `${network?.broker.firstName} ${network?.broker.lastName}`
            : '' || '...',
          brokerID: network?.broker?.agentID || '',
          networkName: network?.networkName,
          marketplace: network?.marketplace,
          networkID: network?.networkID,
          flags: network?.flags,
          defaultManagers: network?.defaultManagers || [],
          allowedLeadTiers: network?.allowedLeadTiers || [],
          allowedStates: network?.allowedStates || [],
          refundPolicy: network?.refundPolicy || '',
          tags: network?.tags?.map((i) => ({ label: i, value: i })) || [],
          verticalTypes: network?.verticalTypes || [],
          defaultVerticalType: network?.verticalType,
          logoURL: network?.logoURL || '',
          dynamicPrice: processingFees('dynamic')?.amount || 0,
          fixedPrice: processingFees('fixed')?.amount || 0,
          overflowPrice: processingFees('overflow')?.amount || 0,
          includeDynamicPrice:
            processingFees('dynamic')?.agentFundedPercent === 100,
          includeFixedPrice:
            processingFees('fixed')?.agentFundedPercent === 100,
          includeOverflowPrice:
            processingFees('overflow')?.agentFundedPercent === 100,
          refundsVisible: network?.refundsVisible || false,
        }}
        validationSchema={yup.object().shape({
          dynamicPrice: yup.number().min(0, 'Price must greater than 0.'),
          fixedPrice: yup.number().min(0, 'Price must greater than 0.'),
          overflowPrice: yup.number().min(0, 'Price must greater than 0.'),
        })}
        onSubmit={(values) => {
          update({
            variables: {
              input: {
                networkID: values.networkID,
                networkName: values.networkName,
                brokerID: values.brokerID,
                marketplace: values.marketplace,
                flags: values.flags,
                allowedLeadTiers: values.allowedLeadTiers.map(
                  (n) => n.priceTierID
                ),
                allowedStates: values.allowedStates,
                refundPolicy: values.refundPolicy,
                defaultManagerIDs: values.defaultManagers.map((n) => n.agentID),
                tags: values.tags.map((n) => n.label),
                verticalTypes: values.verticalTypes.map((n) => n.toLowerCase()),
                verticalType: values.defaultVerticalType,
                logoURL: values.logoURL,
                dynamicPrice: values.dynamicPrice,
                fixedPrice: values.fixedPrice,
                overflowPrice: values.overflowPrice,
                includeDynamicPrice: values.includeDynamicPrice,
                includeFixedPrice: values.includeFixedPrice,
                includeOverflowPrice: values.includeOverflowPrice,
                refundsVisible: values.refundsVisible,
              },
            },
          });
        }}
      >
        {({ setFieldValue, values }) => (
          <>
            <Card className="m-3">
              <CardHeader className="d-flex justify-content-between">
                <CardTitle
                  tag="h5"
                  className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
                >
                  Network Profile
                </CardTitle>
              </CardHeader>
              <CardBody tag={Row} noGutters>
                <Col xs={3}>
                  <Field name="networkName" label="Network Name" />
                </Col>
                <Col
                  xs={{
                    size: 3,
                    offset: 1,
                  }}
                >
                  <Field name="marketplace" label="Marketplace Name" disabled />
                </Col>
                <Col
                  xs={{
                    size: 3,
                    offset: 1,
                  }}
                >
                  <Field name="brokerName" label="Broker" disabled />
                </Col>
                <Col xs={5}>
                  <PriceTierSelect
                    name="allowedLeadTiers"
                    label="Allowed Lead Tiers"
                    placeholder="Network's Allowed Tiers..."
                    networkID={network!.networkID!}
                    isMulti
                  />
                </Col>
                <Col
                  xs={{
                    size: 4,
                    offset: 1,
                  }}
                >
                  <ManagerSelect
                    name="defaultManagers"
                    label="Default Managers"
                    isMulti
                  />
                </Col>
                <Col xs={12}>
                  <SelectStates />
                </Col>
                <Col xs={5}>
                  <Field
                    name="refundPolicy"
                    label="Refund Policy"
                    type="text"
                  />
                </Col>
                <Col
                  xs={{
                    size: 6,
                    offset: 1,
                  }}
                >
                  <SelectField
                    creatable
                    name="tags"
                    label="Tags"
                    labelKey="label"
                    valueKey="value"
                    placeholder="Create Tags..."
                    options={[]}
                    raw
                    isMulti
                    isClearable
                  />
                </Col>
                <Col xs={4}>
                  <SelectField
                    name="defaultVerticalType"
                    label="Default Network Vertical"
                    labelKey="label"
                    valueKey="value"
                    options={
                      network?.verticalTypes?.map((i) => ({
                        label: i[0].toUpperCase() + i.slice(1),
                        value: i,
                      })) || []
                    }
                  />
                </Col>
                <Col
                  xs={{
                    size: 7,
                    offset: 1,
                  }}
                >
                  <SelectField
                    creatable
                    name="verticalTypes"
                    label="Vertical Types"
                    labelKey="label"
                    valueKey="value"
                    placeholder="Create new vertical type..."
                    options={[]}
                    isMulti
                    isClearable
                    getOptionLabel={(opt: any) =>
                      opt?.label || opt[0].toUpperCase() + opt.slice(1)
                    }
                  />
                </Col>
                <Col xs={5}>
                  <Field
                    name="logoURL"
                    label="Logo URL"
                    type="text"
                    placeholder="https://example.jpeg"
                  />
                </Col>
                <Col xs={2} className="text-center">
                  <Label for="refundsVisible">Admin Refunds Tab</Label>
                  <FormGroup>
                    <ButtonToggle
                      active={values.refundsVisible}
                      onToggle={() =>
                        setFieldValue('refundsVisible', !values.refundsVisible)
                      }
                    />
                  </FormGroup>
                </Col>
              </CardBody>
            </Card>
            <Card
              className={classNames('m-3', {
                'd-none': role !== 'super_internal_admin',
              })}
            >
              <CardHeader>
                <CardTitle
                  tag="h5"
                  className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
                >
                  Processing Fees
                </CardTitle>
              </CardHeader>
              <CardBody noGutters>
                <Row className="align-items-center justify-content-center">
                  <Col xs={6}>
                    <MoneyField name="dynamicPrice" label="Dynamic Price" />
                  </Col>
                  <IncludePriceSwitch
                    name="Dynamic"
                    setFieldValue={setFieldValue}
                    value={values?.includeDynamicPrice}
                  />
                </Row>
                <Row className="align-items-center justify-content-center">
                  <Col xs={6}>
                    <MoneyField name="fixedPrice" label="Fixed Price" />
                  </Col>
                  <IncludePriceSwitch
                    name="Fixed"
                    setFieldValue={setFieldValue}
                    value={values?.includeFixedPrice}
                  />
                </Row>
                <Row className="align-items-center justify-content-center">
                  <Col xs={6}>
                    <MoneyField name="overflowPrice" label="Overflow" />
                  </Col>
                  <IncludePriceSwitch
                    name="Overflow"
                    setFieldValue={setFieldValue}
                    value={values?.includeOverflowPrice}
                  />
                </Row>
              </CardBody>
            </Card>
            <div className="float-right mr-3 mb-3">
              <Button color="secondary" type="reset" className="mr-2" size="lg">
                Reset
              </Button>
              <Button
                color="primary"
                size="lg"
                type="submit"
                disabled={updating}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default NetworksGeneralTab;
