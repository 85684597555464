import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  analyticsUrlQuery,
  analyticsAgentsQuery,
  leadAggregationQuery,
} from '@/graphql/queries';
import { useNetwork, usePageLoader } from '@/hooks';
import { AnalyticsSearchBar } from '@/components';
import moment from 'moment';
import omit from 'lodash.omit';
import { lte } from 'lodash';

interface SearchParams {
  dashboardCode: string;
  networkID: string;
  startDate: string;
  endDate: string;
  vendorID?: string;
  layerID?: string;
  mpVendorCampaignName?: string;
  managerID?: string;
}

const createInitialSearchParams = ({
  networkID = '',
  selectedDashboard = {},
}: any): any => {
  let values: any = {
    dashboardCode: selectedDashboard.code || '',
    networkID,
    startDate: moment().format(),
    endDate: moment().format(),
  };

  if (!selectedDashboard.filters) {
    return values;
  }

  values = selectedDashboard.filters.reduce((t: any, f: string) => {
    t[f] = undefined;
    return t;
  }, values);

  return values;
};

const Analytics: React.FunctionComponent = () => {
  const { network } = useNetwork();
  const { complete, start } = usePageLoader();
  const [dashboards, setDashboards] = useState<any[]>([]);
  const [dashboardURL, setDashboardUrl] = useState<string | null>();
  const created: any = {};
  const dateType = 'created';
  const values: any = {};

  const filter = {
    vendorID: values.vendorID || undefined,
    mpVendorCampaignName: values.mpVendorCampaignName || undefined,
    networkID: network?.networkID,
    [values.dateType]:
      created.gte !== undefined && created.lte !== undefined
        ? created
        : undefined,
  };

  const [searchParams, setSearchParams] = useState<SearchParams>(
    createInitialSearchParams({
      networkID: network?.networkID || '',
      dashboard: {},
    })
  );

  const { data, loading, error, refetch } = useQuery(analyticsUrlQuery, {
    variables: {
      dashboardCode: searchParams.dashboardCode,
      networkID: network?.networkID,
      startDate: searchParams.startDate,
      endDate: searchParams.endDate,
      vendorID: searchParams?.vendorID,
      layerID: searchParams?.layerID,
      mpVendorCampaignName: searchParams?.mpVendorCampaignName,
      managerID: searchParams?.managerID,
    },
  });

  const updateSearch = (params: any): void => {
    const {
      dateRange,
      mpVendorCampaignName,
      vendorID,
      layerID,
      managerID,
      dashboardCode,
    } = params;
    refetch({
      dashboardCode,
      networkID: network?.networkID,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      vendorID,
      layerID,
      mpVendorCampaignName,
      managerID,
    });
  };

  useEffect(() => {
    if (network) {
      const { networkID, adminDashboards = [] } = network || {};
      if (adminDashboards) {
        const tempDashboards =
          adminDashboards.length > 0 ? adminDashboards : [];
        setDashboards(tempDashboards);
        if (adminDashboards.length > 0) {
          const tempSearch = createInitialSearchParams({
            networkID,
            selectedDashboard: adminDashboards[0],
          });
          refetch(tempSearch);
        }
      }
    }
  }, [network, refetch]);

  useEffect(() => {
    setDashboardUrl(data?.analyticsUrl.url || '');
  }, [data]);

  useEffect(() => {
    if (loading) {
      start();
    } else {
      complete();
    }
  }, [loading, start, complete]);

  return (
    <>
      <AnalyticsSearchBar
        handleSubmit={(v: any) => updateSearch(v)}
        network={network}
        changeDashboard={(code: string) => {
          const { networkID, adminDashboards = [] } = network || {};
          const dashboard = adminDashboards.find((d) => {
            return d.code === code;
          });
          const tempSearch = createInitialSearchParams({
            networkID,
            selectedDashboard: dashboard,
          });
          setDashboardUrl(null);
          if (refetch) {
            refetch(tempSearch);
          }
        }}
      />
      <div className="d-flex justify-content-center flex-column h-100 w-100">
        {dashboards.length > 0 && dashboardURL ? (
          <>
            <iframe
              frameBorder="0"
              className="h-100"
              src={dashboardURL}
              title="iframe_dashboard"
            />
          </>
        ) : (
          <>
            {loading ? (
              <h3 className="mt-5 text-center">Loading ....</h3>
            ) : (
              <h3 className="mt-5 text-center">
                You are not authorized to see this dashboard
              </h3>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Analytics;
