/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardTitle,
  Button,
  ListGroup,
  Alert,
  Label,
  Row,
  Col,
  Collapse,
} from 'reactstrap';
import classNames from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import { Field } from '@availity/form';
import {
  FaArrowDown,
  FaArrowUp,
  FaQuestionCircle,
  FaTrash,
} from 'react-icons/fa';
import { CopyButton, DebouncedInput, FormikSwitch } from '@/components';
import { SelectField } from '@availity/select';
import { AttributesContext } from '@/context';
import { Attribute } from '@/types';
import CategoryMapField from './CategoryMapField';

const unusedAttTypes = [
  undefined,
  null,
  'email',
  'date',
  'float',
  'ipAddress',
  'phone',
  'url',
];

const SourcesTab: React.FC<{ isNew: boolean }> = ({ isNew }) => {
  const { values, initialValues, errors, setFieldValue } = useFormikContext<{
    categoryMappingEnabled: boolean;
    vendorSourceCampaignMapArray: string[];
    vendorCampaigns: any[];
    defaultVendorCampaign: any;
    apBidEnhancmentEnabled: boolean;
  }>();
  const { postAttributes: attributes } = useContext(AttributesContext);
  const additionalSelectable: any = [
    {
      label: 'mlRangeScore',
      value: 'mlRangeScore',
    },
    {
      label: 'mlResultScore',
      value: 'mlResultScore',
    },
    {
      label: 'phoneType',
      value: 'phoneType',
    },
    {
      label: 'AP Device',
      value: 'device',
    },
    {
      label: 'AP Form Input Method',
      value: 'formInputMethod',
    },
    {
      label: 'AP OS',
      value: 'operatingSystem',
    },
    {
      label: 'Slice Group',
      value: 'sliceGroup',
    },
  ];
  const selectableAttributes = attributes
    ?.filter((att: Attribute) => !unusedAttTypes.includes(att?.attributeType))
    ?.map((att: Attribute) => {
      if (
        att.sourceAttribute === 'householdRange' ||
        att.sourceAttribute === 'incomeRange'
      )
        return {
          label: att.sourceAttribute,
          value: att.sourceAttribute,
        };
      return {
        value: att.internalAttribute,
        label: att.sourceAttribute,
      };
    })
    .concat(additionalSelectable);

  console.log(selectableAttributes);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isNew) setIsOpen(true);
  }, [isNew]);
  return (
    <>
      <Row noGutters className="justify-content-center">
        {isOpen ? (
          <FaArrowUp
            onClick={() => setIsOpen(false)}
            className="my-2 cursor-pointer text-primary"
          />
        ) : (
          <FaArrowDown
            onClick={() => setIsOpen(true)}
            className="my-2 cursor-pointer text-primary"
          />
        )}
        <Collapse isOpen={isOpen}>
          <Alert color="secondary" className="text-light font-italic">
            Categories are a way of classifying different types of leads within
            a lead source. Categorize incoming leads based on their attributes
            such as age ranges, income, zip code, etc. Admins can modify bids or
            route these leads to different price tiers. A category is made from
            a set of categorizers that are based on common attributes vendors
            send. Categories will be given a description to enable visibility
            when assigning them to a tier. Any leads that do not meet the
            criteria of all available categories will go to the default category
            with name matching the lead source description.
          </Alert>
        </Collapse>
      </Row>
      <Row noGutters className="justify-content-center">
        <Alert color="warning" className="w-100">
          Be careful when updating existing categories or mapping. If a category
          is assigned to a tier and updated, a new ID is given and it is removed
          from the price tier. The updated category will need to be added back
          to the price tiers.
        </Alert>
      </Row>
      {values.categoryMappingEnabled && (
        <Card body className="mb-3">
          <CardTitle
            tag="h4"
            className="lead text-uppercase d-flex align-items-center"
          >
            Default Vendor Campaign
            <FaQuestionCircle
              onClick={() => setIsOpen((o) => !o)}
              className="ml-2 text-primary cursor-pointer"
            />
          </CardTitle>
          <Card body className="my-2 py-2 px-4" color="secondary">
            <small className="text-muted d-flex justify-content-between">
              <span>{values.defaultVendorCampaign?.mpVendorCampaignID}</span>
            </small>
            <Row className="my-2">
              <Col>
                <span className="text-light">Campaign Name:</span>
                <span className="h5 font-weight-bold text-primary ml-2">
                  {values.defaultVendorCampaign?.mpVendorCampaignName}
                </span>
              </Col>
            </Row>
          </Card>
        </Card>
      )}
      <Card body className="mb-3">
        <CardTitle
          tag="h4"
          className={classNames(
            'lead text-uppercase d-flex justify-content-between align-items-center',
            {
              'mb-0': !values.categoryMappingEnabled,
            }
          )}
        >
          <span>Category Mapping</span>
          <FormikSwitch
            height={25}
            width={50}
            handleDiameter={25}
            disabled={
              values.categoryMappingEnabled &&
              initialValues.categoryMappingEnabled
            }
            name="categoryMappingEnabled"
          />
        </CardTitle>
        {values.categoryMappingEnabled && (
          <FieldArray
            name="vendorSourceCampaignMapArray"
            render={(arrayHelpers) => (
              <Row>
                {values.vendorSourceCampaignMapArray.map(
                  (att: string, index: number) => {
                    const selectOptions = selectableAttributes
                      ?.filter(
                        (a: any) =>
                          a.value !== att &&
                          !values.vendorSourceCampaignMapArray.includes(a.value)
                      )
                      ?.concat(
                        att
                          ? {
                              value: att,
                              label: selectableAttributes?.find(
                                (a) => a.value === att
                              )?.label as string,
                            }
                          : []
                      );

                    return (
                      <Col xs={3} key={index} className="d-flex">
                        <SelectField
                          name={`vendorSourceCampaignMapArray.${index}`}
                          placeholder="Field Name..."
                          label={`Field ${index + 1}`}
                          labelKey="label"
                          valueKey="value"
                          options={[].concat((selectOptions as []) || [])}
                          groupClass="w-100"
                        />
                        {index !== 0 && (
                          <div style={{ marginTop: 31 }}>
                            <Button
                              color="danger"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                const newFieldValues = values.vendorCampaigns.map(
                                  (vC) => {
                                    if (vC.categoryFields) {
                                      vC.categoryFields.splice(index, 1);
                                    }
                                    return vC;
                                  }
                                );
                                setFieldValue(
                                  'vendorCampaigns',
                                  newFieldValues
                                );
                              }}
                            >
                              <FaTrash />
                            </Button>
                          </div>
                        )}
                      </Col>
                    );
                  }
                )}
                <Col xs={2} className="d-flex align-items-center mt-3">
                  <Button color="primary" onClick={() => arrayHelpers.push('')}>
                    Add Categorizer
                  </Button>
                </Col>
              </Row>
            )}
          />
        )}
      </Card>
      {values.categoryMappingEnabled &&
        values.vendorSourceCampaignMapArray?.length > 0 && (
          <Card body>
            <CardTitle tag="h4" className="lead text-uppercase">
              Field Values
            </CardTitle>
            <FieldArray
              name="vendorCampaigns"
              render={(arrayHelpers) => (
                <ListGroup className="mt-3 border-0">
                  {values.vendorCampaigns?.map((vC: any, index: number) => (
                    <Card
                      body
                      className="my-2 py-2 px-2"
                      color="secondary"
                      key={`${vC.mpVendorCampaignID || ''}-${index}`}
                    >
                      {values.categoryMappingEnabled ? (
                        <>
                          <Row noGutters>
                            <Col xs={3} className="ml-3 mb-3">
                              <span className="mb-4">Category Description</span>
                              <DebouncedInput
                                name={`vendorCampaigns.${index}.description`}
                                className="mt-2"
                                placeholder="Descriptive Title..."
                              />
                              <small className="w-100 text-danger">
                                {errors?.vendorCampaigns?.[index]?.description}
                              </small>
                            </Col>
                            <Col>
                              <small className="text-muted float-right mt-1 mr-3">
                                <span>{vC.mpVendorCampaignID}</span>
                              </small>
                            </Col>
                          </Row>

                          <CategoryMapField
                            index={index}
                            mpVendorCampaignID={vC.mpVendorCampaignID}
                            remove={() => arrayHelpers.remove(index)}
                            apBidEnhancmentEnabled={
                              values.apBidEnhancmentEnabled
                            }
                          />
                          {errors?.vendorCampaigns?.[index] && (
                            <div className="text-danger">
                              {errors.vendorCampaigns[index]?.campaignName}
                            </div>
                          )}
                        </>
                      ) : (
                        <Field
                          name={`vendorCampaigns.${index}.campaignName`}
                          label={
                            <Label>
                              Name{' '}
                              {vC.mpVendorCampaignID && (
                                <small className="text-muted ml-3">
                                  {vC.mpVendorCampaignID}
                                </small>
                              )}
                            </Label>
                          }
                          type="text"
                          placeholder="Description..."
                          helpMessage={
                            vC.campaignName !== 'default'
                              ? 'This is a descriptive name for the leads being generated by'
                              : ''
                          }
                          append={
                            !vC.mpVendorCampaignID ? (
                              <Button
                                color="danger"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <FaTrash />
                              </Button>
                            ) : (
                              <CopyButton
                                color="dark"
                                content={vC.mpVendorCampaignID}
                              />
                            )
                          }
                          disabled={
                            (values.categoryMappingEnabled &&
                              vC.campaignName === 'default') ||
                            !!vC.mpVendorCampaignID
                          }
                        />
                      )}
                    </Card>
                  ))}
                  <Button
                    color="primary"
                    className="mt-3"
                    onClick={() =>
                      arrayHelpers.push({
                        campaignName: '',
                      })
                    }
                  >
                    Add New Category
                  </Button>
                </ListGroup>
              )}
            />
          </Card>
        )}
    </>
  );
};

export default SourcesTab;
