export { default as Vars } from './env-var';

export const currencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const MAX_CENTS = 1000000;

export const formatCurrencyForDisplay = (
  centsInteger: number,
  showCents = true
): string =>
  `$${(centsInteger / 100)
    .toFixed(showCents && centsInteger < MAX_CENTS ? 2 : 0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const MS_PER_DAY = 86400000;

export const MS_PER_YEAR = MS_PER_DAY * 365;

export const MS_PER_99_YEARS = MS_PER_YEAR * 99;

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
