import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { SelectField } from '@availity/select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import { agentPaginationQuery } from '@/graphql/queries';
import { useNetwork } from '@/hooks';

interface ManagerSelectProps extends SelectFieldProps<any> {
  managerID: string;
}

const ManagerSelect: React.SFC<ManagerSelectProps> = ({
  managerID,
  ...props
}) => {
  const { network } = useNetwork();
  const client = useApolloClient();

  const loadOptions = (...args) => {
    const [inputValue, , additional = {}] = args;
    const { page } = additional;

    return client
      .query({
        query: agentPaginationQuery,
        variables: {
          page: page || 1,
          perPage: 25,
          filter: {
            q: inputValue || '',
            managerID,
            networkID: network!.id,
            roles: ['MANAGER', 'ADMIN', 'SUPER_ADMIN'],
          },
          orderBy: {
            firstName: 'DESC',
          },
        },
      })
      .then((response) => {
        const items = response.data.agentPagination.items;

        return {
          options: items,
          hasMore: response.data.agentPagination.pageInfo.hasNextPage,
          additional: {
            ...additional,
            page: page + 1,
          },
        };
      });
  };

  return (
    <SelectField
      pagination
      additional={{
        page: 1,
        managerID,
      }}
      debounceTimeout={350}
      delay={350}
      getOptionLabel={(opt: any) => {
        return `${opt.firstName} ${opt.lastName}`;
      }}
      valueKey="agentID"
      defaultOptions
      raw
      loadOptions={loadOptions}
      {...props}
    />
  );
};

export default ManagerSelect;
