/* eslint-disable no-case-declarations */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Col,
  Row,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  ListGroup,
  Alert,
  Badge,
} from 'reactstrap';
import { DndProvider } from 'react-dnd';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FaSync, FaServer, FaTrash, FaWrench } from 'react-icons/fa';
import { useNetwork, useNotifications } from '@/hooks';
import { queueManyQuery, priceTierManyQuery } from '@/graphql/queries';
import { queueAddTierMutation } from '@/graphql/mutations';
import {
  QueueCreationModal,
  UpdateQueuesModal,
  QueueTerminateModal,
} from '@/modals';
import { RouteComponentProps } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import InfastructureRow from './InfraRow';
import { DroppableTier } from './TierDropZone';

const Infastrucutre: React.SFC<RouteComponentProps> = ({ history }) => {
  const { network: { networkID } = {} } = useNetwork();
  const { create: createNotification } = useNotifications();

  const { data, loading, refetch } = useQuery(queueManyQuery, {
    variables: {
      networkID,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      // uh oh, someone must have tried to be sneaky and access the endpoint
      // or.... this shit broke
      history.push('/');
    },
  });

  const { data: priceTierData, loading: priceTierLoading } = useQuery(
    priceTierManyQuery,
    {
      variables: {
        networkID,
      },
    }
  );

  const [addTierToQueue, { loading: updatingAssignments }] = useMutation(
    queueAddTierMutation,
    {
      onCompleted: () => {
        createNotification('success', 'Tier moved to queue');
      },
      update: (cache, { data: { queueAddTier } }) => {
        const { priceTierID, queueID } = queueAddTier;
        const data = cache.readQuery<any>({
          query: queueManyQuery,
          variables: { networkID },
        });

        refetch({
          networkID,
        });
      },
    }
  );

  const [openModal, setOpenModal] = useState<
    '' | 'queue-creation' | 'queue-update' | 'queue-terminate'
  >('');

  if (loading || priceTierLoading) return null;

  const {
    queueMany: { queues = [], versions = [] },
  } = data || {};

  const missingTiers = priceTierData?.priceTierMany?.filter(
    (tier) =>
      !tier.liveTransfers &&
      !queues.find((q) =>
        q.tiers.find((t) => t.priceTierID === tier.priceTierID)
      )
  );

  return (
    <Formik
      initialValues={{
        selectedQueues: [],
      }}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => {
        const _queues = queues.map((q) => {
          return {
            ...q,
            latestVersion: q.version === versions.slice(-1)[0],
          };
        });

        const isOneSelected = values.selectedQueues.length > 0;

        const addQueue = (queue: any) => {
          setFieldValue('selectedQueues', [...values.selectedQueues, queue]);
        };

        const clearQueues = () => setFieldValue('selectedQueues', []);

        const removeQueue = (instanceId: string) => {
          setFieldValue(
            'selectedQueues',
            values.selectedQueues.filter(
              ({ instanceID: _instanceId }: any) => _instanceId !== instanceId
            )
          );
        };

        return (
          <Container fluid style={{ maxWidth: 1500 }}>
            <div className="mt-5 d-flex align-items-center justify-content-between">
              <h3 className="mb-3">Infrastructure</h3>
            </div>
            <div className="d-flex justify-content-end mb-3">
              <div>
                <Button
                  size="lg"
                  color="primary"
                  onClick={() => {
                    refetch({
                      networkID,
                    });
                  }}
                >
                  <div className="d-flex align-items-center">
                    <FaSync />
                  </div>
                </Button>
                &nbsp;
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="secondary" caret>
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      disabled={!isOneSelected}
                      className="d-flex align-items-center"
                      onClick={() => setOpenModal('queue-update')}
                    >
                      <div className="d-flex align-items-center">
                        <FaWrench />
                        &nbsp; &nbsp;Update Version
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      disabled={!isOneSelected}
                      className="d-flex align-items-center"
                      onClick={() => setOpenModal('queue-terminate')}
                    >
                      <div className="d-flex align-items-center">
                        <FaTrash />
                        &nbsp; &nbsp;Terminate Server
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setOpenModal('queue-creation')}
                      className="d-flex align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <FaServer />
                        &nbsp; &nbsp;Create Server
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>

            <DndProvider backend={HTML5Backend}>
              {missingTiers.length > 0 && (
                <Alert color="info">
                  Tiers are missing Servers:{' '}
                  {missingTiers.map((t) => (
                    <DroppableTier {...t} key={t.priceTierID} />
                  ))}
                </Alert>
              )}
              <ListGroup>
                {_queues.map((q) => (
                  <InfastructureRow
                    key={q.instanceID}
                    addTierToQueue={addTierToQueue}
                    updatingAssignments={updatingAssignments}
                    selectedQueues={values.selectedQueues}
                    addQueue={addQueue}
                    removeQueue={removeQueue}
                    {...q}
                  />
                ))}
              </ListGroup>
            </DndProvider>
            {openModal === 'queue-creation' && (
              <QueueCreationModal
                versions={versions}
                isOpen={openModal === 'queue-creation'}
                close={() => {
                  setOpenModal('');
                  refetch({
                    networkID,
                  });
                }}
              />
            )}
            {openModal === 'queue-update' && (
              <UpdateQueuesModal
                selectedQueues={values.selectedQueues}
                versions={versions}
                removeQueue={removeQueue}
                clearQueues={clearQueues}
                isOpen={openModal === 'queue-update'}
                close={() => setOpenModal('')}
              />
            )}
            {openModal === 'queue-terminate' && (
              <QueueTerminateModal
                versions={versions}
                selectedQueues={values.selectedQueues}
                clearQueues={clearQueues}
                removeQueue={removeQueue}
                isOpen={openModal === 'queue-terminate'}
                close={() => setOpenModal('')}
              />
            )}
          </Container>
        );
      }}
    </Formik>
  );
};

export default Infastrucutre;
