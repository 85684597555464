import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Button,
  Label,
  Card,
  CardBody,
  FormGroup,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { SelectField } from '@availity/select';
import { ButtonToggle } from '@/components';
import { Field } from '@availity/form';
import { FaQuestionCircle } from 'react-icons/fa';

const allowedFilters = [
  {
    label: 'Region',
    value: 'region',
  },
  {
    label: 'Income',
    value: 'incomeInt',
  },
  {
    label: 'Household',
    value: 'householdInt',
  },
  {
    label: 'Age',
    value: 'ageInt',
  },
];

const AdvancedSettings: FC = () => {
  const [breakPoint, setBreakPoint] = useState(0);
  const { values, setFieldValue } = useFormikContext();
  const attributePrefix = 'bulk-price-breakpoints';

  useEffect(() => {
    if (values.priceBreakPoints) {
      setBreakPoint(values?.priceBreakPoints.length - 1);
    }
  }, [values.priceBreakPoints]);

  return (
    <Card className="mb-3">
      <CardHeader className="d-flex justify-content-between align-items-center">
        <CardTitle
          tag="h5"
          className="py-2 h4 mb-0 d-flex justify-content-center align-items-center"
        >
          Advanced Settings{' '}
          <FaQuestionCircle
            className="ml-2 cursor-pointer text-primary"
            id={`${attributePrefix}-tooltip`}
          />
          <UncontrolledTooltip
            target={`${attributePrefix}-tooltip`}
            placement="auto-start"
            style={{ textAlign: 'left' }}
          >
            For advanced users and admins
          </UncontrolledTooltip>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={2}>
            <Label for="maxLeadCountPerDay">Max Leads Per Day</Label>
            <FormGroup>
              <Field
                name="maxLeadCountPerDay"
                type="number"
                style={{ maxWidth: 120 }}
              />
            </FormGroup>
          </Col>
          <Col xs={3}>
            <Label for="maxCampaignCount">Max Campaigns Allowed</Label>
            <FormGroup>
              <Field
                name="maxCampaignCount"
                type="number"
                style={{ maxWidth: 120 }}
              />
            </FormGroup>
          </Col>
          <Col xs={3}>
            <Label for="maxCampaignCount">Max Duplicates</Label>
            <FormGroup>
              <Field
                name="maxDuplicates"
                type="number"
                style={{ maxWidth: 120 }}
              />
            </FormGroup>
          </Col>
          <Col xs={2} className="text-center pl-0">
            <Label for="agentCreditOnly">Agent Credit Only</Label>
            <FormGroup>
              <ButtonToggle
                id={`${attributePrefix}-allow-analytics-agent-credit-only`}
                active={values.agentCreditOnly}
                onToggle={() =>
                  setFieldValue('agentCreditOnly', !values.agentCreditOnly)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={2}>
            <Label for="analyticStreamOn">Analytics Stream</Label>
            <FormGroup>
              <ButtonToggle
                id={`${attributePrefix}-allow-analytics-stream`}
                active={values.analyticStreamOn}
                onToggle={() =>
                  setFieldValue('analyticStreamOn', !values.analyticStreamOn)
                }
              />
            </FormGroup>
          </Col>
        </Row>

        {!values.liveTransfers && (
          <Row className="mt-2">
            <Col xs={4}>
              <SelectField
                groupClass="mt-2"
                id={`${attributePrefix}-allowed-filters-dropdown`}
                name="allowedFilters"
                label="Allowed Filters"
                options={allowedFilters}
                placeholder="Allowed Filters"
                isMulti
              />
            </Col>
            <Col xs={2} className="text-center pl-0 mt-2">
              <Label for="skipDupCheck">Skip Dup Check</Label>
              <FormGroup>
                <ButtonToggle
                  id={`${attributePrefix}-skipDupCheck`}
                  active={values.skipDupCheck}
                  onToggle={() =>
                    setFieldValue('skipDupCheck', !values.skipDupCheck)
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={2} className="text-center pl-0 mt-2">
              <Label for="addDupBackToQueue">Add DupBack To Queue</Label>
              <FormGroup>
                <ButtonToggle
                  id={`${attributePrefix}-addDupBackToQueue`}
                  active={values.addDupBackToQueue}
                  onToggle={() =>
                    setFieldValue(
                      'addDupBackToQueue',
                      !values.addDupBackToQueue
                    )
                  }
                />
              </FormGroup>
            </Col>
            {/* <Col xs={8}>
              <SelectField
                id={`${attributePrefix}-allowed-filters-dropdown`}
                name="allowedDestinations"
                label="Allowed Destinations"
                options={allowedDestinations}
                placeholder="Allowed Destinations"
                isMulti
              />
            </Col> */}
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default AdvancedSettings;
