import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdownProps,
} from 'reactstrap';

export const acaVerification = {
  attributes: ['postalCode'],
  include: true,
  level: 'company',
  resultAttribute: 'acaEligible',
  attributeType: 'string',
  type: 'ADDON',
};

export const callWindow = {
  attributes: ['postalCode'],
  include: true,
  level: 'company',
  resultAttribute: 'callWindowSegment',
  attributeType: 'string',
  type: 'ADDON',
};

// export const povertyLevel = {
//   attributes: ['incomeInt', 'householdInt'],
//   include: true,
//   level: 'company',
//   resultAttribute: 'povertyLevel',
//   attributeType: 'string',
//   type: 'ADDON',
// };

export const povertyLevel = (ruleID: string): any => {
  console.log(ruleID);
  return {
    settings: { ruleID },
    attributes: ['incomeInt', 'householdInt'],
    include: true,
    level: 'company',
    resultAttribute: 'povertyLevel',
    attributeType: 'string',
    type: 'ADDON',
  };
};

export const activeProspectClaim = {
  attributes: ['trustedFormCertID'],
  include: true,
  level: 'company',
  settings: {
    storeAnalytics: false,
  },
  resultAttribute: 'activeProspectClaim',
  attributeType: 'json',
  type: 'ADDON',
};

export const activeProspectAnalytics = {
  attributes: ['trustedFormCertID'],
  include: true,
  level: 'company',
  settings: {
    storeAnalytics: true,
  },
  resultAttribute: 'activeProspect',
  attributeType: 'json',
  type: 'ADDON',
};

export const hoScoringWithOverride = {
  attributes: [
    'vendorID',
    'vendorSubID',
    'domain',
    'weekhour',
    'postalCode',
    'age',
    'exclusivity',
    'income',
  ],
  include: true,
  level: 'company',
  settings: {
    priceOverride: true,
  },
  resultAttribute: 'hoScoring',
  attributeType: 'json',
  type: 'ADDON',
};

export const hoScoring = {
  attributes: [
    'vendorID',
    'vendorSubID',
    'domain',
    'weekhour',
    'postalCode',
    'age',
    'exclusivity',
    'income',
  ],
  include: true,
  level: 'company',
  settings: {
    priceOverride: false,
  },
  resultAttribute: 'hoScoring',
  attributeType: 'json',
  type: 'ADDON',
};

const getAddOnOptions = (network: any): any => {
  const { corpID, povertyLevelIDs = [] } = network;
  const plObject = povertyLevel('');
  const defaultEligibility = [
    {
      label: 'ACA Eligible',
      value: acaVerification,
    },
    {
      label: 'Call Window',
      value: callWindow,
    },
  ];

  console.log(povertyLevelIDs);
  if (povertyLevelIDs.length > 0) {
    const plObject = povertyLevel(povertyLevelIDs[0]);
    defaultEligibility.push({
      label: 'Poverty Level',
      value: plObject,
    });
  }

  const defaultEnhancement: any[] = [];
  const nonUSHAEnhancement = [
    {
      label: 'Active Prospect Claim',
      value: activeProspectClaim,
    },
    {
      label: 'Active Prospect Analytics',
      value: activeProspectAnalytics,
    },
  ];
  const ushgEnhancement = [
    {
      label: 'HO Score Price Override',
      value: hoScoringWithOverride,
    },
    {
      label: 'HO Score',
      value: hoScoring,
    },
    {
      label: 'Active Prospect Analytics',
      value: activeProspectAnalytics,
    },
  ];
  const ushaEligibilty = [...defaultEligibility];
  const nonushaEligibilty = [...defaultEligibility];

  const ushaEnhancement = [...defaultEnhancement, ...ushgEnhancement];
  const nonushaEnhancment = [...defaultEnhancement, ...nonUSHAEnhancement];

  return [
    {
      label: 'Eligibility',
      filter: 'resultAttribute',
      options: corpID === 'usha' ? ushaEligibilty : nonushaEligibilty,
    },
    {
      label: 'Enhancement',
      filter: 'resultAttribute',
      options: corpID === 'usha' ? ushaEnhancement : nonushaEnhancment,
    },
  ];
};

interface AddonDropdowProps extends UncontrolledButtonDropdownProps {
  push: (attr: any) => void;
}

const AddonDropdown: React.FC<AddonDropdowProps> = ({
  push,
  filters,
  network,
  ...props
}) => {
  return (
    <UncontrolledButtonDropdown {...props}>
      <DropdownToggle style={{ height: 'fit-content' }} caret>
        Add Ons
      </DropdownToggle>
      <DropdownMenu>
        {getAddOnOptions(network).map((category) => (
          <React.Fragment key={category.label}>
            <DropdownItem header>{category.label}</DropdownItem>
            {(category.filter
              ? category.options.filter((opt) => {
                  let value = opt.value[category.filter];
                  if (Array.isArray(value)) {
                    value = value.join(',');
                  }
                  return !filters.find((f) => {
                    let filterValue = f[category.filter];
                    if (Array.isArray(filterValue)) {
                      filterValue = filterValue.join(',');
                    }
                    if (f.functionName) {
                      return f.functionName === opt.value.functionName;
                    }
                    return filterValue === value && f.type === opt.value.type;
                  });
                })
              : category.options
            ).map((option: any) => (
              <DropdownItem
                id={`filter-dropdown-${category.label
                  .replace(/\s+/g, '-')
                  .toLowerCase()}-${option.label
                  .replace(/\s+/g, '-')
                  .toLowerCase()}`}
                onClick={() => push(option.value)}
                key={option.label}
              >
                {option.label}
              </DropdownItem>
            ))}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default AddonDropdown;
