import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const access_obj = {
  support: {
    page: {
      allowed: ['agents', 'agent_detail', 'leads', 'analytics'],
      base_redirect: (
        <Route exact path="/">
          <Redirect to="/agents" />
        </Route>
      ),
    },
    agent: {
      allowed: ['general', 'integrations', 'agents', 'campaigns'],
      actions: ['reset_password'],
      search: ['status'],
    },
    lead: {
      allowedColumns: [
        'leadID',
        'phone',
        'agentEmail',
        'price',
        'leadType',
        'campaignName',
        'isDuplicate',
        'passDNC',
        'crmContactID',
        'isCorpDuplicate',
        'result',
      ],
      actions: [],
    },
  },
  admin: {
    page: {
      allowed: [
        'price_tier',
        'price_tier_id',
        'agent_detail',
        'agents',
        'reports',
        'dashboard',
        'leads',
        'lead_sources',
        'lead_sources_id',
        'refunds',
        'analytics',
      ],
    },
    agent: {
      allowed: ['general', 'integrations', 'agents', 'campaigns'],
      actions: ['reset_password', 'delete_agent'],
      search: [],
    },
    lead: {
      allowedColumns: [
        'leadID',
        'phone',
        'agentEmail',
        'price',
        'leadType',
        'isDuplicate',
        'passDNC',
        'crmContactID',
        'corpLeadID',
        'mpVendorCampaignName',
        'mpVendorCampaignID',
        'result',
        'date',
        'isCorpDuplicate',
        'duplicateFromDate',
        'addedFromDate',
        'createdEpoch',
        'vendorLeadID',
        'campaignName',
        'vendorName',
        'vendorPrice',
        'email',
        'firstName',
        'lastName',
        'region',
        'conferenceRecording',
      ],
      actions: ['export'],
    },
  },
  super_internal_admin: {
    page: {
      allowed: [
        'refunds',
        'price_tier',
        'price_tier_id',
        'agent_detail',
        'agents',
        'reports',
        'dashboard',
        'leads',
        'lead_sources',
        'lead_sources_id',
        'infastructure',
        'network',
        'vendor',
        'vendor_detail',
        'analytics',
      ],
    },
    vendor: { allowed: ['general'] },
    network: { allowed: ['general', 'lists', 'base'] },
    agent: {
      allowed: ['general', 'integrations', 'agents', 'campaigns', 'services'],
      actions: ['reset_password', 'change_region', 'delete_agent'],
      search: ['status'],
    },
    lead: {
      allowedColumns: [
        'leadID',
        'phone',
        'agentEmail',
        'price',
        'leadType',
        'isDuplicate',
        'passDNC',
        'crmContactID',
        'corpLeadID',
        'mpVendorCampaignName',
        'mpVendorCampaignID',
        'result',
        'date',
        'isCorpDuplicate',
        'duplicateFromDate',
        'addedFromDate',
        'createdEpoch',
        'vendorLeadID',
        'campaignName',
        'vendorName',
        'vendorPrice',
        'email',
        'firstName',
        'lastName',
        'region',
        'conferenceRecording',
      ],
      actions: ['export'],
    },
  },
  internal_admin: {
    page: {
      allowed: [
        'refunds',
        'price_tier',
        'price_tier_id',
        'agent_detail',
        'agents',
        'reports',
        'dashboard',
        'leads',
        'lead_sources',
        'lead_sources_id',
        'network',
        'vendor',
        'vendor_detail',
        'analytics',
      ],
    },
    vendor: { allowed: ['general'] },
    network: { allowed: ['general', 'lists', 'base'] },
    agent: {
      allowed: ['general', 'integrations', 'agents', 'campaigns', 'services'],
      actions: ['reset_password', 'change_region', 'delete_agent'],
      search: ['status'],
    },

    lead: {
      allowedColumns: [
        'leadID',
        'phone',
        'agentEmail',
        'price',
        'leadType',
        'isDuplicate',
        'passDNC',
        'crmContactID',
        'corpLeadID',
        'mpVendorCampaignName',
        'mpVendorCampaignID',
        'result',
        'date',
        'isCorpDuplicate',
        'duplicateFromDate',
        'addedFromDate',
        'createdEpoch',
        'vendorLeadID',
        'campaignName',
        'vendorName',
        'vendorPrice',
        'email',
        'firstName',
        'lastName',
        'region',
        'conferenceRecording',
      ],
      actions: ['export'],
    },
  },
} as any;

export { access_obj as default };
