import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { vendorDashboardQuery } from '@/graphql/queries';
import { useNetwork } from '@/hooks';
import DashboardItem from './DashboardItem';

const LeadSourceItem: React.SFC<any> = ({
  created,
  vendorName,
  leadType,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const { network } = useNetwork();
  const toggleExpanded = () => setExpanded((o) => !o);

  const { data, loading } = useQuery(vendorDashboardQuery, {
    skip: !expanded,
    pollInterval: 300000,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        networkID: network?.networkID,
        vendorName,
        mpVendorCampaignName: props.name,
        key: 'region.keyword',
        created,
        leadType,
      },
    },
  });

  const regions = data?.dashboards?.vendors.items || [];

  return (
    <DashboardItem
      toggleExpanded={toggleExpanded}
      expanded={expanded && !loading}
      expanding={loading}
      {...props}
    >
      <div className="mt-3">
        <h4 className="pl-3">States</h4>
        {regions.map((region: any) => (
          <DashboardItem
            color="dark"
            className="mt-3 mx-3 border-top border-dark"
            key={`${vendorName}-${props.name}-${region.name}`}
            openExportsModal={props.openExportsModal}
            queryParams={{
              r: region.name,
              v: vendorName,
              vc: props.name,
              fr: created.gte,
              to: created.lte,
              lt: leadType,
            }}
            {...region}
          />
        ))}
      </div>
    </DashboardItem>
  );
};

const VendorItem: React.SFC<any> = ({ created, leadType, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const { network } = useNetwork();
  const toggleExpanded = () => setExpanded((o) => !o);
  // TODO - we can do better cacheing where we track all the queries and their poll intervals
  // and then just refetch all at one time. Or if the user hasnt done anything on the dashboard
  // Then lets just wait until they are back to refetch
  const { data, loading } = useQuery(vendorDashboardQuery, {
    skip: !expanded,
    pollInterval: 300000,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        networkID: network?.networkID,
        vendorName: props.name,
        key: 'mpVendorCampaignName.keyword',
        created,
        leadType,
      },
    },
  });

  const vendorCampaigns = data?.dashboards?.vendors.items || [];

  return (
    <DashboardItem
      toggleExpanded={toggleExpanded}
      expanded={expanded && !loading}
      expanding={loading}
      queryParams={{
        v: props.name,
        fr: created.gte,
        to: created.lte,
        lt: leadType,
      }}
      {...props}
    >
      <div className="mt-3">
        <h4 className="pl-3">Lead Sources</h4>
        {vendorCampaigns.map((vc: any) => (
          <LeadSourceItem
            color="secondary"
            className="mt-3"
            key={`${props.name}-${vc.name}`}
            vendorName={props.name}
            {...vc}
            openExportsModal={props.openExportsModal}
            created={created}
            leadType={leadType}
            queryParams={{
              v: props.name,
              vc: vc.name,
              fr: created.gte,
              to: created.lte,
              lt: leadType,
            }}
            displayName={vc.name
              .split('_')
              .splice(
                vc.name.startsWith('usha_') ||
                  vc.name.startsWith('jf_') ||
                  vc.name.startsWith('jb_') ||
                  vc.name.startsWith('cg_') ||
                  vc.name.startsWith('next_') ||
                  vc.name.startsWith('aged_') ||
                  vc.name.startsWith('quick_') ||
                  vc.name.startsWith('mass_') ||
                  vc.name.startsWith('trademarc_') ||
                  vc.name.startsWith('calendly_') ||
                  vc.name.startsWith('old_') ||
                  (vc.name.startsWith('quote') &&
                    !vc.name.startsWith('quoteStorm') &&
                    !vc.name.startsWith('quoteHound') &&
                    !vc.name.startsWith('quotewizard'))
                  ? 2
                  : 1
              )
              .join(' ')}
          />
        ))}
      </div>
    </DashboardItem>
  );
};

export default VendorItem;
