import gql from 'graphql-tag';

import { vendorPartial } from './VendorFragment';
import { VendorCampaignBase } from './VendorCampaignFragment';

const VendorLayerFragment = gql`
  fragment VendorLayerFragment on VendorLayer {
    __typename
    id
    layerID
    vendorID
    networkID
    brokerID
    description
    tags
    verticalType
    kindOfLead
    addonPrice
    vendor {
      ${vendorPartial}
    }
    vendorCampaigns{
      ${VendorCampaignBase}
    }
    liveTransfersEnabled
    minimumRefresh
    autoProcessThreshold
    autoProcess
    vendorSourceCampaign
    mpVendorCampaignName
    vendorSourceCampaignMap
    vendorPrice
    maxDemandUnsoldAccepted
    splitCount
    enhanceProfile
    rejectDuplicates
    rejectDisconnected
    rejectDNC
    apiType    
    maxSpendPerHour
    maxSpend
    pingPostEnabled
    pingPostType
    fixedBidPrice
    legacySync
    rejectDuplicates
    pendingLeads
    legacySync
    errorLeads
    refundEligible
    isCampaignDestination
    skipActiveDemandSubmit
    overideDuplicateChecks
    allowMLOverride
    allowMLearning
    bestPriceAcceptence
    allowAPOnPing
    overrideBlockedAttributeFields{
      label
      value
    }
    overrideBlockedValues{
      label
      value
    }
    allowSlices
    allowSplits
    isUpdateEligible
    delaySecondsForUpdate
    filters{
        __typename
        ... on VendorLayerAddOnFilter{
         description
        }
        ... on VendorLayerVerifyFilter{
         description
        }
        ... on VendorLayerSystemFilter{
        description
        }
        ... on VendorLayerTimeFilter{
         description
        }
        ... on VendorLayerStaticFilter{
         description
        }
        ... on VendorLayerMultiFilter{
         description
        }
        ... on VendorLayerRangeFilter{
         description
        }
        ... on VendorLayerDynamicFilter {
         description
        }
    }
    apBidEnhancmentEnabled
    apSettings{
        apBidEnhancmentDynamic
        apPercentageEnabled
        apWPMMin
        apWPMMax
        apKPMMin
        apKPMMax
        apBidPrice
        apBidPercentage
    }
    newAgedUpload
  }
`;

export default VendorLayerFragment;
