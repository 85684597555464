import gql from 'graphql-tag';

const QueueFragment = gql`
  fragment QueueFragment on Queue {
    __typename
    id
    instanceID
    status
    marketplace
    fixedCampaignName
    loopDelay
    cycleDuration
    lastCompletionEpoch
    logs
    priceTierID
    tiers {
      priceTierID
      name
    }
    version
    DEPLOYMENT_STATUS
    SERVER_STATUS
  }
`;

export default QueueFragment;
